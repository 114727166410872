import React from "react";

import "./SizesConfigurations.scss";

import SizesImg1 from "../../Assets/images/windows/patio/sizes1.png";
import SizesImg2 from "../../Assets/images/windows/patio/sizes2.png";
import SizesImg3 from "../../Assets/images/windows/patio/sizes3.png";
import SizesImg4 from "../../Assets/images/windows/patio/sizes4.png";

const dataSizes = [
  {
    id: 0,
    img: SizesImg1,
    alt: "SizesImg1",
    number: 1,
    sizes: [
      "59 ½” X 79 ¼”",
      "71 ½” X 79 ¼”",
      "95 ½” X 79 ¼”",
      "59 ½” X 81 ¼”",
      "71 ½” X 81 ¼”",
    ],
  },
  {
    id: 1,
    img: SizesImg2,
    alt: "SizesImg2",
    number: 2,
    sizes: [
      "89 7/16 ” X 79 ¼”",
      "107 7/16” X 79 ¼”",
      "143 7/16” X 79 ¼”",
      "89 7/16” X 81 ¼”",
      "107 7/16” X 81 ¼”",
    ],
  },
  {
    id: 2,
    img: SizesImg3,
    alt: "SizesImg3",
    number: 3,
    sizes: [
      "89 ¼” X 79 ¼”",
      "107 ¼” X 79 ¼”",
      "143 ¼” X 79 ¼”",
      "89 ¼” X 81 ¼”",
      "107 ¼” X 81 ¼”",
    ],
  },
  {
    id: 3,
    img: SizesImg4,
    alt: "SizesImg4",
    number: 4,
    sizes: [
      "116 ⅜” X 79 ¼”",
      "140 ⅜” X 79 ¼”",
      "188 ⅜” X 79 ¼”",
      "116 ⅜” X 81 ¼”",
      "140 ⅜” X 81 ¼”",
    ],
  },
];

export default function SizesConfigurations() {
  return (
    <section className="sizesSection">
      <h2>Sizes and Configuration</h2>
      <h3>
        Take control of your patio door by choosing its size and its opening
        configuration, ensuring it fits your home and lifestyle flawlessly.
      </h3>
      <div className="SizesContainer">
        {dataSizes.map((item) => (
          <div key={item.id} className="SizesContainer__item">
            <div className="SizesContainer__item_img">
              <img src={item.img} alt={item.alt} />
            </div>
            <p className="SizesContainer__item_number">{item.number}</p>
            {item.sizes.map((size) => (
              <p key={size}>{size}</p>
            ))}
          </div>
        ))}
      </div>
    </section>
  );
}
