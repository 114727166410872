import React from "react";

export const Experience = (props) => {
  return (
    <section className="about-section-1">
      <div className="container">
        <h3>
          {/* <span>Experience</span> leads to expertise! */}
          <span>Backed by Decades of Experience</span> Since 1998
        </h3>
        {Array.isArray(props.paragraph)
          ? props.paragraph.map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))
          : props.paragraph}
      </div>
    </section>
  );
};
