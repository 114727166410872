import React from "react";

import building from "../../Assets/images/design_center.png";
import Email from "../../Assets/images/email.svg";
import Pin from "../../Assets/images/pin.svg";
import Phone from "../../Assets/images/phone.svg";
import Information from "../../Assets/images/information.svg";

const DEFAULT_EMAIL = "service@agmrenovations.com";
const DEFAULT_LOCATIONS = [
  {
    title: "Vaughan",
    address: "45 Tigi Court",
    city: "Concord",
    province: "ON",
    postal: "L4K 5E4",
  },
  {
    title: "Oshawa",
    address: "1797 William Lott Dr",
    city: "Oshawa",
    province: "ON",
    postal: "L1H 7K5",
  },
];

export const ContactInfo = (props) => {
  const { email = DEFAULT_EMAIL, locations = DEFAULT_LOCATIONS } = props;

  return (
    <section className="about-section-5">
      <div className="container">
        <div className="centerText">
          <h3>
            <span>AGM Office</span>
          </h3>
        </div>
        <div className="agmOffice">
          <div className="agmInfo">
            <div className="section-1">
              {locations.map((dataOjb, index) => (
                <div key={index} className="sectionContainer">
                  <div className="icon">
                    <img src={Pin} alt="pin icon" />
                  </div>
                  <div className="content">
                    <h3>{dataOjb.title} Location</h3>
                    {dataOjb.address}
                    <br />
                    {dataOjb.city}, {dataOjb.province} {dataOjb.postal}
                  </div>
                </div>
              ))}
            </div>

            <div className="section-2">
              <div className="sectionContainer">
                <div className="icon">
                  <img src={Phone} alt="phone icon" />
                </div>
                <div className="content">
                  <h3>Phone Number</h3>
                  <a href={"tel:" + props.phone}>{props.phone}</a>
                </div>
              </div>
              <div className="sectionContainer">
                <div className="icon">
                  <img src={Email} alt="email icon" />
                </div>
                <div className="content">
                  <h3>Email</h3>
                  <a href={"mailto:" + email}>{email}</a>
                </div>
              </div>
              <div className="sectionContainer">
                <div className="icon information">
                  <img src={Information} alt="information icon" />
                </div>
                <div className="content">
                  <h3>Hours</h3>
                  <b>Monday - Friday</b>
                  <br></br>8 am - 6 pm
                  <br></br>
                  <b>Saturday</b>
                  <br></br>
                  10 am -4 pm
                  <br></br>
                  <b>Sunday</b>
                  <br></br>
                  By Appointment
                  <br></br>
                </div>
              </div>
            </div>
          </div>
          <div className="picture">
            <img src={building} alt="office building"></img>
          </div>
        </div>
      </div>
    </section>
  );
};
