import { useState, useEffect } from "react";

export const breakpoints = {
  md: 1920,
  sm: 1440,
  xs: 980,
};

function getWindowSizeName(windowWidth) {
  if (windowWidth > 1440) {
    return breakpoints.md;
  }

  if (windowWidth <= 1440 && windowWidth > 980) {
    return breakpoints.sm;
  }

  return breakpoints.xs;
}

export function useBreakPoint() {
  const [breakpoint, setWindowSize] = useState("");

  useEffect(() => {
    function handleResize() {
      const currentPoint = getWindowSizeName(window.innerWidth);

      if (currentPoint !== breakpoint) {
        setWindowSize(currentPoint);
      }
    }

    window.addEventListener("resize", handleResize);

    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [breakpoint]);

  return breakpoint;
}
