import React from "react";
import { StepWindowType } from "./StepWindowType";
import "./TypesOfWindows.scss";

export const TypesOfWindows = ({ typesWindowsData, title, subtitle }) => {
  return (
    <section id="typesOfWindows">
      {title ? <h3>{title}</h3> : <h3>New Roffing</h3>}
      {subtitle ? <h4>{subtitle}</h4> : null}
      <div className="typeOfWindows__container">
        {typesWindowsData.map((item, index) => (
          <StepWindowType
            key={index}
            img={item.img}
            title={item.title}
            description={item.description}
            paragraths={item.paragraths}
            revert={item.revert}
            listItems={item.listItems}
            txtI={item.txtI}
          />
        ))}
      </div>
    </section>
  );
};
