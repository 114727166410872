import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Box';
import { withRouter } from 'react-router-dom';

class ThankYou extends Component {

  constructor(){
    super();
    this.state = {
    }
  }

  render() {
    return (
      <Box mt={8} mb={15}>
        <Container maxWidth="sm">
          <div className="text-center">
            {/* <h1>Thank You</h1>
            <p>Someone from the AGM team will reach out shortly.</p> */}
          </div>
        </Container>
        <Container maxWidth="lg" style={{ marginTop: '20px' }}>
          <iframe src="https://main.d2al4m2800vlku.amplifyapp.com/" width="100%" style={{ minHeight: "730px" }} frameBorder="0"/>
        </Container>
      </Box>
    );
  }
}

export default withRouter(ThankYou);