import React, { Component, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Radio, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import axios from "axios";
import Logo from "./logo.svg";
import "./bathroomlayout.scss";
import JsZip from "jszip";
import imageCompression from "browser-image-compression";
import { v4 as uuidv4 } from "uuid";
import UploadAws from "../Middleware/Upload";
import ReactDOM from 'react-dom';
import ReactPDF from '@react-pdf/renderer';
import { PDFViewer, BlobProvider, Document, Page, pdf, usePDF} from '@react-pdf/renderer';
import setup from '../../Views/setup'
import pdfDocument from "../../Views/bathroomForm"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(3),
  },
  checked: {
    "&$checked": {
      color: "#00aede",
    },
  },
}));

export default function BathroomLayout() {
  const [state, setState] = React.useState({
    address: "",
    ageHome: "",
    sizeOfHome: "",
    powderRoom: false,
    layout: "",
    backwall: "",
    frontwall: "",
    leftwall: "",
    rightwall: "",
    morephotos1: "",
    morephotos2: "",
    error: "",
    submitted: false,
    bathroom3pc: false,
    masterBathroom4pc: false,
    loading: false,
  });
  const {
    powderRoom,
    layout,
    backwall,
    frontwall,
    leftwall,
    rightwall,
    morephotos1,
    morephotos2,
    bathroom3pc,
    masterBathroom4pc,
  } = state;

  useEffect(() => {
    if (
      state.masterBathroom4pc ||
      state.powderRoom ||
      state.bathroom3pc ||
      state.address !== "" ||
      state.ageHome !== "" ||
      state.sizeOfHome !== ""
    ) {
      setState({ ...state, error: "" });
    }
  }, [
    state.masterBathroom4pc,
    state.powderRoom,
    state.bathroom3pc,
    state.address,
    state.ageHome,
    state.sizeOfHome
  ]);
  const handleLayoutSubmit = async (files, bodyFormData) => {
    let upload = await UploadAws(files)
      .then((response) => {
        if (response) {
          console.log(response);
          axios({
            method: "post",
            url: "https://hooks.zapier.com/hooks/catch/2915270/olzna85",
            data: bodyFormData,
          })
          .then(function (response) {
            //handle success
            setState({ ...state, submitted: true });
            window.scrollTo(0, 0);
          })
          .catch(function (err) {
            //handle error
            let data = {
              error: "Bathroom Emailing had an error: " + err,
            };
            axios({
              method: "post",
              url: "https://hooks.zapier.com/hooks/catch/2915270/o7z1y05/",
              data: JSON.stringify(data),
            });
            setState({
              ...state,
              error:
                "There was an issue with the request, please contact: Service@agmrenovations.com",
              loading: false,
            });
          });
        }
      })
      .catch((err) => {
        let data = {
          error: "Bathroom uploading had an error: " + err,
        };
        axios({
          method: "post",
          url: "https://hooks.zapier.com/hooks/catch/2915270/o7z1y05/",
          data: JSON.stringify(data),
        });
        setState({
          ...state,
          error:
            "There was an issue with the request, please contact: Service@agmrenovations.com",
          loading: false,
        });
      });
  };
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const handleFile = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.files[0],
      error: "",
    });
  };
  const handleText = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };
  const handleCompression = async (img) => {
    const imageFile = img;
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      return compressedFile;
    } catch (error) {
      console.log(error);
    }
  };

  const generateFile = () => {
    if (
      state.address === "" ||
      state.ageHome === 0 ||
      state.sizeOfHome === 0 ||
      state.layout === ""
    ) {
      setState({ ...state, error: "Please fill in required fields" });
      return;
    }
    if (!state.masterBathroom4pc && !state.bathroom3pc && !state.powderRoom) {
      setState({ ...state, error: "Please select bathroom type" });
      return;
    }
    // if (
    //   !state.layout ||
    //   !state.backwall ||
    //   !state.frontwall ||
    //   !state.leftwall ||
    //   !state.rightwall
    // ) {
      // setState({ ...state, error: "Please upload all wall images" });
      // return;
    //}
    const data = {
      address: state.address,
      ageHome: state.ageHome,
      sizeOfHome: state.sizeOfHome,
      powderRoom: state.powderRoom,
      submitted: state.submitted,
      bathroom: state.bathroom3pc,
      masterBathroom: state.masterBathroom4pc,
    };
    const images = {
      layout: state.layout,
      backwall: state.backwall,
      frontwall: state.frontwall,
      leftwall: state.leftwall,
      rightwall: state.rightwall,
      morephotos1: state.morephotos1,
      morephotos2: state.morephotos2,
    };

    var zipFile = new JsZip();
    for (const key in images) {
      console.log(images[key]);
      if (images[key] !== "" && images[key] !== undefined) {
        if (
          images[key].type === "image/png" ||
          images[key].type === "image/jpeg"
        ) {
          let fileType = images[key].type;
          zipFile.file(key+"."+fileType, handleCompression(images[key]), {
            binary: true,
          });
        } else if (images[key].type === "application/pdf") {
            zipFile.file(key+".pdf", images[key]);
        } else {
          setState({
            ...state,
            error: "File type not supported, please use JPG, PNG or PDF",
          });
          return;
        }
      } else {
        console.log('file skipped')
        continue;
      } 
    }
    
    setState({
      ...state,
      loading: true,
    });
    zipFile.generateAsync({
        type: "blob",
        compression: "DEFLATE",
      })
      .then(function (content) {
        let uid = uuidv4();
        var files = new File([content], `bathroom_layout-${uid}.zip`);
        var bodyFormData = new FormData();
        var fileSize = files.size / 1024;
        if (fileSize > 25000) {
          setState({
            ...state,
            error:
              "All files combined exceed 25mbs, please compress files before trying again",
          });
          return;
        }
        bodyFormData.append("fileName", `bathroom_layout-${uid}.zip`);
        for (let key in data) {
          bodyFormData.append(key, data[key]);
        }

        //const urlPDF = generatePDF(data, images, uid);        
        //console.log(urlPDF);
        handleLayoutSubmit(files, bodyFormData);
      });
  };

  // const logPDFUrl = (url) => {
  //   console.log(url);
  // }
  
  const generatePDF = async (formData, images, uid) => {
    // console.log(formData);
    // ReactPDF.render(<pdfDocument />, `${__dirname}/Views/bathroom-${uid}.pdf`);

    setState({
      submitted:true,
      formData:formData,
      formImages:images,
      formUID:uid
    });

    //ReactDOM.render(<App form={formData} images={images} />, document.getElementById('root'));
    
    const MyDoc = <pdfDocument form={formData} images={images} uid={uid} />

    //const blob = pdf(MyDoc).toBlob();
    //console.log(blob);


    // let urlPDF;

    // const element = <BlobProvider document={MyDoc}>
    //   {({ blob, url, loading, error }) => {
    //     urlPDF = url
    //   }}
    // </BlobProvider>;

    // console.log(element);
    // return urlPDF;
  
    // <BlobProvider document={MyDoc}>
    //   {({ blob, url, loading, error }) => {
    //     if (loading) {                
    //     }
    //     if (!loading && url) {
    //       return (
    //         <a href={url} download>
    //           Download
    //         </a>
    //       );
    //     }
    //     if (error) {
    //       console.error(error);
    //       return <p>An error occurred</p>;
    //     }
    //     return null;
    //   }}
    // </BlobProvider>
    
};

  const error = [powderRoom, masterBathroom4pc, bathroom3pc].filter((v) => v).length !== 2;
  const classes = useStyles();

  //const MyDoc = <pdfDocument form={state.formData} images={state.formImages} uid={state.FormUID}/>;
  //{state.submitted && <setup form={state.formData} images={state.formImages} uid={state.FormUID}></setup>}

  // const App = () => {
  //   const [instance, updateInstance] = usePDF({ document: MyDoc });

  //   if (instance.loading) return <div>Loading ...</div>;

  //   if (instance.error) return <div>Something went wrong: {error}</div>;

  //   return (
  //     <a href={instance.url} download="test.pdf">
  //       Download
  //     </a>
  //   );
  // }

  return (
    <div className={classes.root}>
      {state.submitted && (
        <h1 className="thankyouBathroom">
          Your form has been submited, Thank you
        </h1>
      )}
      
      {!state.submitted && (
        <div>
          {" "}
          <div className="bathroomBanner">
            <img src={Logo} id="formLogo" />
            <div className="inner-bathroom-content">
              <p className="bathroomTitle">YOUR NEW BATHROOM IS JUST A</p>
              <p className="bathroomTitle">FEW CLICKS AWAY!</p>
              <p className="bathroomDescription">
                In order for our team to provide you with a detailed quote for
                your bathroom, please fill out the form below and upload hand
                drawings and pictures of your bathroom.
              </p>
            </div>
          </div>
          <Grid container spacing={3} direction="column">
            <form id="bathroomForm">
              <label style={{ color: "grey" }}>
                <span style={{ color: "red" }}>IMPORTANT</span>: All uploaded
                files combined must not exceed 25mbs & we currently accepts JPG,
                PNG & PDF
              </label>
              <Grid item container direction="column" className="houseInfo">
                <Grid item xs={12}>
                  <label>
                    Address <span className="required">*</span>
                  </label>
                  <TextField
                    id="address"
                    required
                    variant="outlined"
                    fullWidth
                    onChange={handleText}
                    name="address"
                  ></TextField>
                </Grid>
              </Grid>
              <Grid item container direction="row" xs className="houseInfo">
                <Grid item xs={12} sm={6} id="ageHome">
                  <label>
                    Age of the Home <span className="required">*</span>
                  </label>
                  <TextField
                    onChange={handleText}
                    name="ageHome"
                    required
                    variant="outlined"
                  ></TextField>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <label>
                    Size of the Home <span className="required">*</span>
                  </label>
                  <TextField
                    id="sizeOfHome"
                    required
                    onChange={handleText}
                    name="sizeOfHome"
                    variant="outlined"
                    style={{ width: "100%" }}
                  ></TextField>
                </Grid>
              </Grid>
              <Grid item container direction="column">
                <Grid item xs style={{ marginTop: "20px" }}>
                  Please Scan your Home Layout Plan from the builder, or sketch
                  a hand-drawn Layout (of your current bathroom) on a graph
                  paper, see link:
                  <a href="https://bit.ly/3aPHeqH" target="_blank">
                    {" "}
                    https://bit.ly/3aPHeqH
                  </a>{" "}                  
                </Grid>
                <Grid
                  item
                  xs
                  style={{ marginBottom: "30px" }}
                  id="bathroomUpload"
                >
                  <FormControlLabel
                    control={
                      <Button variant="contained" component="label" style={{ marginRight:'10px' }}>
                        Choose File
                        <input
                          type="file"
                          style={{ display: "none" }}
                          onChange={handleFile}
                          name="layout"
                        />
                      </Button>
                    }
                    label={layout.name}
                  />
                </Grid>
                <Grid item container direction="row">
                  <Grid item container direction="column" xs={12} sm={8}>
                    <label id="bathroomType">
                      {" "}
                      SELECT YOUR BATHROOM TYPE{" "}                      
                    </label>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={masterBathroom4pc}
                          onChange={handleChange}
                          name="masterBathroom4pc"
                          color="default"
                          classes={{
                            checked: classes.checked,
                          }}
                        />
                      }
                      label="Master Bathroom 4pc (shower, bathtub, vanity, toliet)"
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          checked={bathroom3pc}
                          onChange={handleChange}
                          name="bathroom3pc"
                          color="default"
                          classes={{
                            checked: classes.checked,
                          }}
                        />
                      }
                      label="Bathroom 3pc (shower/bathtub, vanity, toliet)"
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          checked={powderRoom}
                          onChange={handleChange}
                          name="powderRoom"
                          color="default"
                          classes={{
                            checked: classes.checked,
                          }}
                        />
                      }
                      label="Powder room (toliet, sink)"
                    />
                  </Grid>
                </Grid>
                <Grid item xs>
                  <p id="bathroomType" style={{ marginTop: "10px" }}>
                    FROM AS FAR WAY AS POSSIBLE - TAKE A HORIZONTAL PICTURE OF
                    EACH BATHROOM WALL
                  </p>
                  <hr
                    style={{
                      marginBottom: "25px",
                      backgroundColor: "#43c4e4",
                      height: "1px",
                      border: "none",
                    }}
                  ></hr>
                </Grid>
                <Grid item container direction="row">
                  <Grid
                    item
                    container
                    direction="column"
                    xs={12}
                    sm={6}
                    id="leftBathroomFrom"
                  >
                    <Grid item xs>
                      <label>
                        FRONT Wall
                      </label>
                      <Grid
                        item
                        xs
                        style={{ marginBottom: "20px", paddingRight: "20px" }}
                        id="bathroomUpload"
                      >
                        <FormControlLabel
                          control={
                            <Button variant="contained" component="label" style={{ marginRight:'10px' }}>
                              Choose File
                              <input
                                type="file"
                                style={{ display: "none" }}
                                onChange={handleFile}
                                name="frontwall"
                              />
                            </Button>
                          }
                          label={frontwall.name}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs>
                      <label>
                        {" "}
                        LEFT Wall 
                      </label>
                      <Grid
                        item
                        xs
                        id="bathroomUpload"
                        style={{ marginBottom: "20px" }}
                      >
                        <FormControlLabel
                          control={
                            <Button variant="contained" component="label" style={{ marginRight:'10px' }}>
                              Choose File
                              <input
                                type="file"
                                style={{ display: "none" }}
                                onChange={handleFile}
                                name="leftwall"
                              />
                            </Button>
                          }
                          label={leftwall.name}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs>
                      <label>
                        {" "}
                        More Photos 
                      </label>
                      <Grid
                        item
                        xs
                        id="bathroomUpload"
                        style={{ marginBottom: "20px" }}
                      >
                        <FormControlLabel
                          control={
                            <Button variant="contained" component="label" style={{ marginRight:'10px' }}>
                              Choose File
                              <input
                                type="file"
                                style={{ display: "none" }}
                                onChange={handleFile}
                                name="morephotos1"
                              />
                            </Button>
                          }
                          label={morephotos1.name}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item container direction="column" xs={12} sm={6}>
                    <Grid item xs>
                      <label>
                        BACK Wall 
                      </label>
                      <Grid
                        item
                        xs
                        id="bathroomUpload"
                        style={{ marginBottom: "20px" }}
                      >
                        <FormControlLabel
                          control={
                            <Button variant="contained" component="label" style={{ marginRight:'10px' }}>
                              Choose File
                              <input
                                type="file"
                                style={{ display: "none" }}
                                onChange={handleFile}
                                name="backwall"
                              />
                            </Button>
                          }
                          label={backwall.name}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs>
                      <label>
                        Right Wall 
                      </label>
                      <Grid
                        item
                        xs
                        id="bathroomUpload"
                        style={{ marginBottom: "20px" }}
                      >
                        <FormControlLabel
                          control={
                            <Button variant="contained" component="label" style={{ marginRight:'10px' }}>
                              Choose File
                              <input
                                type="file"
                                style={{ display: "none" }}
                                onChange={handleFile}
                                name="rightwall"
                              />
                            </Button>
                          }
                          label={rightwall.name}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs>
                      <label>
                        More Photos 
                      </label>
                      <Grid
                        item
                        xs
                        id="bathroomUpload"
                        style={{ marginBottom: "20px" }}
                      >
                        <FormControlLabel
                          control={
                            <Button variant="contained" component="label" style={{ marginRight:'10px' }}>
                              Choose File
                              <input
                                type="file"
                                style={{ display: "none" }}
                                onChange={handleFile}
                                name="morephotos2"
                              />
                            </Button>
                          }
                          label={morephotos2.name}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  justify="center"
                  alignItems="center"
                  container
                  direction="column"
                >
                  {state.error && <p className="required">{state.error}</p>}
                  {!state.loading ? (
                    <Button
                      variant="contained"
                      component="label"
                      style={{
                        width: "50%",
                        fontSize: "20px",
                        color: "#fff",
                        backgroundColor: "#43c4e4",
                      }}
                      type="submit"
                      value="Submit"
                      onClick={generateFile}
                    >
                      Submit
                    </Button>
                  ) : (
                    <label>Submitting Request...</label>
                  )}
                </Grid>
              </Grid>
            </form>
          </Grid>
        </div>
      )}
    </div>
  );
}