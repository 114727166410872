import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import "./Styles/styles.scss";

import GeneralBanner from "./GeneralBanner";
import LoadingElement from "../../Common/LoadingElement";

import {
  Button,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from "reactstrap";
import axios from "axios";

import img1 from "../../Assets/images/Careers/Careers01.png";
import img2 from "../../Assets/images/Careers/Careers02.png";
import img3 from "../../Assets/images/Careers/Careers03.png";
import img4 from "../../Assets/images/Careers/Careers04.png";

// import dotsImg from "../../Assets/images/dots.png";
import ReactPlayer from "react-player";

const warrantyUrl =
  "https://agm-warrantyform-api.herokuapp.com/api/submitResume";
// const ZapierCareersUrl = "https://hooks.zapier.com/hooks/catch/2915270/o0khf4v/";
const ZapierCareersUrl =
  "https://hooks.zapier.com/hooks/catch/2915270/bay95ji/";

const Career = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [position, setPosition] = useState("");
  const [file, setFile] = useState("");
  // const [url, setUrl] = useState("")
  // const [success, setSuccess] = useState(false)
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();

  // Perform the upload
  const handleUpload = (ev) => {
    ev.preventDefault();

    if (!file || !firstName || !lastName || !email || !position) {
      // TODO add validation Formic.js ?
      return;
    }

    setLoading(true);

    let fileParts = file.name.split(".");
    let fileName = fileParts[0];
    let fileType = fileParts[1];

    axios
      .post(warrantyUrl, { fileName, fileType })
      .then((response) => {
        var returnData = response.data.data.returnData;
        var signedRequest = returnData.signedRequest;

        // setUrl({ url: returnData.url });

        var options = {
          headers: {
            "Content-Type": fileType,
            "Content-Disposition": "attachment",
          },
        };

        axios
          .put(signedRequest, file, options)
          .then((result) => {
            handleSubmit(ev, returnData.url, result);
          })
          .catch((error) => {
            console.log("ERROR " + JSON.stringify(error));
          });
      })
      .catch((error) => {
        alert(JSON.stringify(error));
      });
  };

  const handleSubmit = (e, url, result) => {
    e.preventDefault();
    const formData = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      positionApply: position,
      resume: url,
    };

    var xhr = new XMLHttpRequest();

    xhr.addEventListener("load", () => {
      const res = JSON.parse(xhr.responseText);

      if (res.status === "success") {
        history.push("/thank-you");
        // setLoading(false);
        // setSuccess(true);
      } else {
        // console.error("RES ZapierCareersUrl error", res);
      }
    });
    xhr.open("POST", ZapierCareersUrl);
    xhr.send(JSON.stringify(formData));
  };

  // const SuccessMessage = () => (
  //   <div style={{padding: 10}}>
  //     <h3 style={{color: "green"}}>YOUR RESUME UPLOAD SUCCESSFULLY</h3>
  //     <a href={url}>Access the file here</a>
  //     <br/>
  //   </div>
  // );

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_TITLE_CAREERS}</title>
      </Helmet>
      <GeneralBanner
        img={"Career090.jpg"}
        page={"careers"}
        title="Join Our Team"
      ></GeneralBanner>
      <div className="finished careers">
        <div className="container">
          <div className="row">
            <div
              id="submit_resume_cont"
              className="col-12 col-md-4 block-d p-4"
              style={{ background: "#f8f8f8" }}
            >
              <b className="title-submit">SUBMIT YOUR RESUME</b>

              <LoadingElement loading={isLoading}>
                <form
                  onSubmit={handleUpload}
                  id="submit_resume"
                  className="row"
                  encType="multipart/form-data"
                >
                  <div className="col-12 col-md-6 p-2">
                    <InputGroup>
                      <Input
                        onChange={(e) => {
                          setFirstName(e.target.value);
                        }}
                        name="firstName"
                        placeholder="First Name *"
                      />
                    </InputGroup>
                  </div>
                  <div className="col-12 col-md-6 p-2">
                    <InputGroup>
                      <Input
                        onChange={(e) => {
                          setLastName(e.target.value);
                        }}
                        name="lastName"
                        placeholder="Last Name *"
                      />
                    </InputGroup>
                  </div>
                  <div className="col-12 p-2">
                    <InputGroup>
                      <Input
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        name="email"
                        placeholder="Email *"
                      />
                    </InputGroup>
                  </div>
                  {/* <div className="col-12 p-2">
                    <InputGroup>
                      <Input onChange={this.updateEstimateFormData} name="phone" placeholder="Phone *" />
                    </InputGroup>
                  </div> */}
                  <div className="col-12 p-2">
                    <InputGroup>
                      <Input
                        onChange={(e) => {
                          setPosition(e.target.value);
                        }}
                        name="positionApply"
                        placeholder="Position Applying For? *"
                      />
                    </InputGroup>
                  </div>
                  <div className="col-12 p-2">
                    <label
                      style={
                        file
                          ? {
                              backgroundColor: "rgb(57 207 230)",
                              color: "#fff",
                            }
                          : {}
                      }
                      for="resume_file"
                    >
                      {file ? file.name : "Browse & Upload Your Resume"}
                    </label>
                    <InputGroup>
                      <input
                        id="resume_file"
                        onChange={(e) => {
                          setFile(e.target.files[0]);
                        }}
                        // ref={(ref) => {
                        //   // this.uploadInput = ref;
                        // }}
                        type="file"
                      />
                    </InputGroup>
                  </div>
                  {/* <div className="col-4 p-2">
                    <button onClick={this.handleUpload}>UPLOAD</button>
                  </div> */}

                  <input
                    type="submit"
                    className="btn-custom mt-2 mod"
                    value="SEND"
                  />

                  <p className="note">
                    <i>Our Team Will Contact You Within 24 Hours*</i>
                  </p>
                </form>
              </LoadingElement>

              {/* {success ? <SuccessMessage/> : null} */}
            </div>
            <div
              className="col-12 col-md-8 block-d p-4"
              style={{ background: "#f8f8f8" }}
            >
              <div className="col-12 col-md-12 block-d mb-4">
                <h2 className="title-h2" style={{ textAlign: "center" }}>
                  Are you looking for a job that is more than just a job?
                </h2>
              </div>
              <div className="paragraph_cont">
                <div
                  className="paragraph"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    alignItems: "center",
                  }}
                >
                  <p>
                    Do you want to look forward to going to work each day? Are
                    you a hard worker with a positive attitude and a strong
                    sense of responsibility? Do you want to work for a company
                    that cares about you and wants you to be successful?
                  </p>
                  <p>
                    {" "}
                    If you have answered “yes” to these questions, AGM
                    Renovations wants to talk to you! We are actively seeking
                    people with skills in a wide variety of areas, who can work
                    with us on a full-time or contract basis.
                  </p>

                  {/* <img src={dotsImg} className="dots"/> */}
                </div>
                <div className="videoContainer">
                  <ReactPlayer
                    controls={true}
                    rel={0}
                    autoPlay={false}
                    url="https://www.youtube.com/watch?v=M87HKVoCvWQ"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-grey">
          <div className="container">
            <div className="row text-center">
              <div className="col-12">
                <h2 className="py-4">The Qualities We Value Most</h2>
              </div>
              <div className="col-10 offset-1 rounded bg-white block-c">
                <div className="row text-left">
                  <div className="col-12 col-md-6 qualitiesDescription">
                    <h2
                      style={{
                        color: "#071d35",
                        lineHeight: "25pt",
                        textAlign: "left",
                        marginBottom: "25px",
                      }}
                    >
                      What we offer to committed, hard-working staff:
                    </h2>
                    <ul className="col-10">
                      <li>Fair compensation</li>
                      <li>
                        A positive working environment that you will want to go
                        to every day
                      </li>
                      <li>Opportunities for advancement</li>
                      <li>
                        Increased responsibility for those who prove themselves
                      </li>
                      <li>
                        Opportunities for learn new skills or upgrade existing
                        ones
                      </li>
                    </ul>
                  </div>
                  <div className="col-12 col-md-6 text-center quailitiesGallery">
                    <h3
                      style={{
                        color: "#081d36",
                        fontSize: "27px",
                        fontWeight: "bold",
                      }}
                    >
                      People with a high level of positive energy
                    </h3>
                    <div className="row p-3 text-center">
                      <div className="col-12 col-md-6 mt-3 mt-md-0">
                        <img src={img4} />
                        <br />
                        <small>
                          The resourcefulness to seek solutions to problems or
                          better ways of doing things
                        </small>
                      </div>
                      <div className="col-12 col-md-6 mt-3 mt-md-0">
                        <img src={img3} />
                        <br />
                        <small>
                          A habit of showing up on time and following through
                          with commitments
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row text-left block-c bg-grey coreValues">
                  <div
                    className="col-12 col-md-6 p-4"
                    style={{ textAlign: "left" }}
                  >
                    <h2
                      style={{
                        color: "#313131",
                        fontSize: "65px",
                        lineHeight: "64px",
                        fontWeight: "300",
                        textAlign: "left",
                        marginBottom: "25px",
                        padding: "0px 0px",
                        fontFamily: "Quicksand, sans-serif",
                      }}
                    >
                      Core Values
                    </h2>
                    <ul>
                      <li>
                        We strive to be{" "}
                        <span className="blue" style={{ fontWeight: "400" }}>
                          the best
                        </span>{" "}
                        at what we do
                      </li>
                      <li>
                        {" "}
                        We play as a{" "}
                        <span className="blue" style={{ fontWeight: "400" }}>
                          team
                        </span>
                      </li>
                      <li>
                        {" "}
                        We are unafraid to
                        <span className="blue" style={{ fontWeight: "400" }}>
                          {" "}
                          admit mistakes
                        </span>
                      </li>
                      <li>
                        {" "}
                        We listen, we service,
                        <span className="blue" style={{ fontWeight: "400" }}>
                          {" "}
                          because we care
                        </span>
                      </li>
                      <li>
                        <span className="blue" style={{ fontWeight: "400" }}>
                          We are passionate
                        </span>{" "}
                        about whatwe do
                      </li>
                      <li>
                        <span className="blue" style={{ fontWeight: "400" }}>
                          We do whatever it takes to
                        </span>{" "}
                        deliver on our promises
                      </li>
                      <li>
                        We always{" "}
                        <span className="blue" style={{ fontWeight: "400" }}>
                          deliver on time
                        </span>
                      </li>
                    </ul>
                    <div className="submitResume">
                      <a href="#submit_resume_cont" className="mt-4 btn-custom">
                        SUBMIT YOUR RESUME!
                      </a>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 p-4 bg-white quailitiesGallery-1">
                    <div className="row p-3 text-center">
                      <div className="col-12 col-md-6 mt-3 mt-md-0">
                        <img src={img2} />
                        <br />
                        <small>A solid work ethic</small>
                      </div>
                      <div className="col-12 col-md-6 mt-3 mt-md-0">
                        <img src={img1} />
                        <br />
                        <small>
                          The ability to work individually and as part of a team
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 pt-3 pb-3">
                <p className="para-footer">
                  We are not just an employer. We treat our staff with respect
                  and we reward hard work and dedication!
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <div id="jazzhr" style={iframe} className="container">
            <iframe style={iframe} name="resumator-job-frame" id="resumator-job-frame" className="resumator-advanced-widget" src="//agmrenovations.applytojob.com/apply/jobs/" width="100%" scrolling="no" frameBorder="0" allowtransparency="true"></iframe>
          </div> */}
      </div>
    </>
  );
};

export default Career;
