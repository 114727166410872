import React from "react";
import { DoorsNav } from "../../DoorsNav/DoorsNav";
import { TopIntro } from "../../../Common/ServicesPages/TopIntro";
import { Features } from "../../../Common/ServicesPages/Features";
import { WindowsIntro as CallToAction } from "../../OurWork/windows/WindowsIntro";

import Insulatiom from "../../../Assets/images/roof/home/insulation.png";
import Siding from "../../../Assets/images/roof/home/Siding.png";
import Skylights from "../../../Assets/images/roof/home/skylights.png";
import SoffitFascia from "../../../Assets/images/roof/home/soffit-fascia.png";
import RoofLeakRepairs from "../../../Assets/images/roof/home/roof-leak-repairs.png";
import NewRoof from "../../../Assets/images/roof/home/new-roofing.png";

import ShingleRoof from "../../../Assets/images/roof/newRoof/shingle.png";
import CedarRoof from "../../../Assets/images/roof/newRoof/cedar.png";
import SlateRoof from "../../../Assets/images/roof/newRoof/slate.png";
import MetalRoof from "../../../Assets/images/roof/newRoof/metal.png";
import ClayRoof from "../../../Assets/images/roof/newRoof/Commercial Roofing.png";
import Flat from "../../../Assets/images/roof/newRoof/flat.png";

import bcknd_top from "../../../Assets/images/roof/newRoof/newRoofing-backg.png";
import backgroundMobile from "../../../Assets/images/roof/newRoof/newRoofingTopBackgMobile.png";
import features_bcknd from "../../../Assets/images/roof/newRoof/ourRoofingBackg.png";
import OurColection from "../../OurColection/OurColection";
import RoofServices from "../../../Assets/images/roof/newRoof/ourRoofingServicesMobile.png";
import FeelingBackgroundMobile from "../../../Assets/images/roof/newRoof/bottomBackgMobile.png";
import calltoaction_bcknd from "../../../Assets/images/roof/newRoof/newRoofing-bottomBackg.png";
const doorMenu = [
  {
    id: 0,
    img: NewRoof,
    alt: "NewRoofing",
    path: "new-roofing",
    title: "New Roofing",
  },
  {
    id: 1,
    img: RoofLeakRepairs,
    alt: "RoofLeakRepairs",
    path: "/roof-leak-repairs",
    title: "Roof Leak Repairs",
  },
  {
    id: 2,
    img: Insulatiom,
    alt: "Insulatiom",
    path: "insulation",
    title: "Insulation",
  },
  {
    id: 3,
    img: SoffitFascia,
    alt: "SoffitFascia",
    path: "soffit-fascia-eavestrought",
    title: "Soffit, Fascia & Eavestrough",
    // left: "-48px",
    // top: "-8px",
  },
  {
    id: 4,
    img: Skylights,
    alt: "Skylights",
    path: "skylights",
    title: "Skylights",
    // left: "-48px",
    // top: "-8px",
  },
  // {
  //   id: 5,
  //   img: Siding,
  //   alt: "Siding",
  //   path: "siding",
  //   title: "Siding",
  //   // left: "-48px",
  //   // top: "-8px",
  // },
];
const className = "roof";
const TopText = {
  title: "Your Roof Replacement Project Begins Today",
  subtitle: `Whether your roof has served you well for a number of years or has endured irreparable damage, it may require an entire replacement. No matter the scope of work, keeping your family safe and home investment protected is always top priority.`,
  subtitle2: `To get started, book your free consultation with our team of industry experts. From the quote and project planning, to selecting your roof and completing the work, we will be with you every step of the way.`,
  btnTxt: "BOOK FREE CONSULTATION",
};

const FeaturesText = {
  title: "Our Roofing Services",
  containerClass: "roof",
  subtitle: "At AGM Renovations, we guarantee:",
  btntxt: "GET FREE QUOTE",
  checkList: [
    "Projects completed on time and within budget",
    "Upfront pricing with no added fees or hidden costs",
    "Excellence with decades of customer satisfaction",
    "Commitment to quality in all that we do",
    "Trusted guidance, information and transparency",
    // " Lifetime Warranty & Satisfaction Guaranteed",
    // "Friendly & Understanding Customer Service",
  ],
};

const OurWorks = [
  { id: 0, img: ShingleRoof, alt: "ShingleRoof", title: "Shingle Roof" },
  { id: 1, img: CedarRoof, alt: "CedarRoof", title: "Cedar Roof" },
  { id: 2, img: SlateRoof, alt: "SlateRoof", title: "Slate Roof" },
  {
    id: 3,
    img: MetalRoof,
    alt: "MetalRoof",
    title: "Metal Roof",
  },
  { id: 4, img: ClayRoof, alt: "Commercial Roof", title: "Commercial Roof" },
  { id: 5, img: Flat, alt: "Flat Roof", title: "Flat Roof" },
];

const NewRoofing = () => {
  return (
    <section>
      <DoorsNav
        className={className}
        doorMenu={doorMenu}
        active="new-roofing"
      />
      <TopIntro
        backgroundImage={bcknd_top}
        backgroundSm={bcknd_top}
        backgroundMobile={backgroundMobile}
        {...TopText}
      />
      <Features
        backgroundImage={features_bcknd}
        // checkList={checkList}
        imgMobile={RoofServices}
        {...FeaturesText}
      />
      <OurColection
        // subtitle={ourDoors}
        items={OurWorks}
        title={"Your Roofing Options"}
      />
      <CallToAction
        backgroundImageIntro={calltoaction_bcknd}
        backgrounMobileIntro={FeelingBackgroundMobile}
        textHead="Ready to Get Started?"
        textSubHead="Book your free consultation with AGM Renovations today!"
        textBtn="GET STARTED"
      />
    </section>
  );
};

export default NewRoofing;
