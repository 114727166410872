import React from "react";
import { DoorsNav } from "../../DoorsNav/DoorsNav";
import { TopIntro } from "../../../Common/ServicesPages/TopIntro";
import { Features } from "../../../Common/ServicesPages/Features";
import { TypesOfWindows } from "../../HowItWorks/windows/TypesOfWindows";
import { WindowsIntro as CallToAction } from "../../OurWork/windows/WindowsIntro";

import Insulatiom from "../../../Assets/images/roof/home/insulation.png";
import Siding from "../../../Assets/images/roof/home/Siding.png";
import Skylights from "../../../Assets/images/roof/home/skylights.png";
import Soffit from "../../../Assets/images/roof/home/soffit-fascia.png";
import RoofLeakRepairs from "../../../Assets/images/roof/home/roof-leak-repairs.png";
import NewRoofing from "../../../Assets/images/roof/home/new-roofing.png";

import bcknd_top from "../../../Assets/images/roof/Soffit/soffitTopBackg.png";
import features_bcknd from "../../../Assets/images/roof/Soffit/soffitOurServices.png";
import calltoaction_bcknd from "../../../Assets/images/roof/Soffit/soofitBottomBackg.png";

import SoffitServices from "../../../Assets/images/roof/Soffit/soffit.png";
import Fascia from "../../../Assets/images/roof/Soffit/fascia.png";
import Eavestrought from "../../../Assets/images/roof/Soffit/eavestrough.png";

import backgroundMobile from "../../../Assets/images/roof/Soffit/topBackgMobile.png";
import ServicesMobile from "../../../Assets/images/roof/Soffit/servicesMobile.png";
import FeelingBackgroundMobile from "../../../Assets/images/roof/Soffit/bottomBackgMobile.png";

const doorMenu = [
  {
    id: 0,
    img: NewRoofing,
    alt: "NewRoofing",
    path: "new-roofing",
    title: "New Roofing",
  },
  {
    id: 1,
    img: RoofLeakRepairs,
    alt: "RoofLeakRepairs",
    path: "/roof-leak-repairs",
    title: "Roof Leak Repairs",
  },
  {
    id: 2,
    img: Insulatiom,
    alt: "Insulatiom",
    path: "insulation",
    title: "Insulation",
  },
  {
    id: 3,
    img: Soffit,
    alt: "SoffitFascia",
    path: "soffit-fascia-eavestrought",
    title: "Soffit, Fascia & Eavestrough",
    // left: "-48px",
    // top: "-8px",
  },
  {
    id: 4,
    img: Skylights,
    alt: "Skylights",
    path: "skylights",
    title: "Skylights",
    // left: "-48px",
    // top: "-8px",
  },
  // {
  //   id: 5,
  //   img: Siding,
  //   alt: "Siding",
  //   path: "siding",
  //   title: "Siding",
  //   // left: "-48px",
  //   // top: "-8px",
  // },
];
const className = "roof";

const TopText = {
  title: "Soffit, Fascia & Eavestrough",
  subtitle: `With the right approach, you can take preventative measures to ensure the quality and safety of your home. This of course includes the accurate installation of soffit, fascia and eavestrough to direct water flow properly. `,
  subtitle2: `Your soffit, fascia and eavestrough will help to direct the water on the exterior of your home to your gutters, effectively protecting the foundation and underlying structure of your home. `,
  btnTxt: "LEARN MORE",
};
const FeaturesText = {
  title: "Installation Services",
  containerClass: "roof",
  subtitle: "The AGM Renovations Difference",
  btntxt: "GET FREE QUOTE",
  checkList: [
    "Many years of combined industry experience",
    "Quality installation to ensure a lasting result",
    "Complete transparency and ongoing communication",
    "Projects completed on time and within budget",
    "No hidden fees or added costs",
    // " Lifetime Warranty & Satisfaction Guaranteed",
    // "Friendly & Understanding Customer Service",
  ],
};

const typesWindowsData = [
  {
    id: 0,
    img: SoffitServices,
    title: "Soffit",
    paragraths: [
      ` At AGM Renovations, our experts agree that most roofs are made with an
      extension that extends past the edge of the house. This extension is
      known as a rafter, with the underside being the soffit. The soffit
      provides this overhang with a finished and aesthetically-pleasing
      appearance. Without the soffit, you would see bare rafters, which
      isn’t the most appealing!`,
      `Our services include:`,
    ],
    listItems: [
      "On-site inspection of the soffit",
      "Installation of new soffit",
      "Repair or replacement of existing soffit",
    ],
  },
  {
    id: 1,
    img: Fascia,
    title: "Fascia",
    paragraths: [
      ` The long, straight board that’s installed along the lower edge of your
      roof is called the fascia. The fascia often provides a home with a
      finished look, adding visual interest and appeal with the right
      approach. The functional benefits of the fascia is that it provides
      support to the lower edge or bottom row of tiles.`,
      `Gutters are also often attached to the fascia, which work with the
      soffit to help ensure that rainwater and snow melt is moved from the
      walls and foundation of your home. It also acts as a barrier to
      wildlife, such as bats, birds and squirrels, further protecting your
      home from mother nature.`,
      `Our services include:`,
    ],
    listItems: [
      "On-site inspection of the fascia",
      "Installation of new fascia",
      "Repair or replacement of existing fascia",
    ],

    revert: true,
  },
  {
    id: 2,

    img: Eavestrought,
    title: "Eavestrough",
    paragraths: [
      ` Believe it or not, eavestroughs play a key role in the construction
      and long-term function of your home. Eavestroughs are designed to help
      direct the water away from the elements of your roof, including the
      shingles, underlayment and bricks. Then, your eavestrough helps the
      water to properly drain away from your home, far from where it could
      impact your foundation.`,
      `Our services include:`,
    ],
    listItems: [
      "On-site inspection of the eavestrough",
      "Installation of a new eavestrough",
      "Repair or replacement of existing eavestrough",
    ],
  },
];
const ourWorkTitle = "Soffit, Fascia & Eavestrough";
const SoffitFascia = () => {
  return (
    <section>
      <DoorsNav
        className={className}
        doorMenu={doorMenu}
        active="soffit-fascia-eavestrought"
      />

      <TopIntro
        backgroundImage={bcknd_top}
        backgroundSm={bcknd_top}
        backgroundMobile={backgroundMobile}
        {...TopText}
      />
      <Features
        backgroundImage={features_bcknd}
        // checkList={checkList}
        imgMobile={ServicesMobile}
        {...FeaturesText}
      />
      <TypesOfWindows
        typesWindowsData={typesWindowsData}
        title={ourWorkTitle}
        subtitle="Installation and Repair"
      />

      <CallToAction
        backgroundImageIntro={calltoaction_bcknd}
        backgrounMobileIntro={FeelingBackgroundMobile}
        textHead="Let’s Get Started"
        textSubHead="Contact your trusted local experts for help with your soffit, fascia and eavestroughs."
        textBtn="BOOK FREE CONSULTATION"
      />
    </section>
  );
};

export default SoffitFascia;
