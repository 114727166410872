import React from "react";

import {
  TopSection,
  Content,
  ContactInfo,
  WhoWeAre,
  HowWeStarted,
  OurValues,
  Experience,
  CallToAction,
} from "../../Containers/About";


import Modal from "./WhyAgm/Modal";
const handler = {};

export const AboutUsPage = (props) => {
  let phone;
  let email;
  let locations;

  if (props.data) {
    if (props.isKitchenApp) {
      phone = props.data.kitchen_phone;
    } else {
      phone = props.data.bathroom_phone;
    }

    email = props.data.email;
    locations = props.data.location;
  }

  let region = "";
  if (sessionStorage.getItem("region")) {
    region = sessionStorage.getItem("region");
  } else {
    region = "";
  }

  const callToActionProps = {
    // text: `To find out how decades of trusted industry experience can
    // help your bathroom renovation project take flight, call or
    // reach out online today.`,
    paragrapht: [
      ` It’s never been easier to renovate your home. Get your free quote, and
    our experts will move through your project right alongside you!`,
      ` We make the journey to sparkling windows and stunning doors
    convenient, fun, and, most importantly, easy!`,
    ],
    // text: (
    //   <>
    //     <p>
    //       It’s never been easier to renovate your home. Get your free quote, and
    //       our experts will move through your project right alongside you!
    //     </p>
    //     <p>
    //       We make the journey to sparkling windows and stunning doors
    //       convenient, fun, and, most importantly, easy!
    //     </p>
    //   </>
    // ),

    className: "windows",
  };

  const contentData = {
    title: "Who Are We?", //"The #1 Bathroom Design & Renovation Experts",
    paragrapht: [
      `We’re your go-to roofing replacement team. We’ve been in the business since 1998 and have elevated the industry
    standard. We’re highly rated and have earned steady recognition for
    our quality craftsmanship and customer service over the last 20 years. Our team of experts can transform your home with breathtaking views
    and luxury doors for unmatched curb appeal!`,
    ],
    // text: (
    //   <>
    //     <p>We’re your go-to window and door renovation team.</p>

    //     <p>
    //       We’ve been in the business since 1998 and have elevated the industry
    //       standard. We’re highly rated and have earned steady recognition for
    //       our quality craftsmanship and customer service over the last 20 years.
    //     </p>

    //     <p>
    //       Our team of experts can transform your home with breathtaking views
    //       and luxury doors for unmatched curb appeal!
    //     </p>
    //   </>
    // ),
  };

  const experienceInfo = {
    paragraph: [
      `Nearly 97% of all projects in the home renovation industry are either
    finished late or over budget, with only 2.5% of all projects in North
    America being completed on time without costing you more money!`,
      `AGM Renovations has a strong reputation for quality and excellence,
    built on many successful years in the industry. Our proven track
    record of finishing projects on time and on budget is second to none.`,
    ],
    // paragraph: (
    //   <>
    //     <p>
    //       Nearly 97% of all projects in the home renovation industry are either
    //       finished late or over budget, with only 2.5% of all projects in North
    //       America being completed on time without costing you more money!
    //     </p>
    //     <p>
    //       AGM Renovations has a strong reputation for quality and excellence,
    //       built on many successful years in the industry. Our proven track
    //       record of finishing projects on time and on budget is second to none.
    //     </p>
    //   </>
    // ),
  };
  const whoWeAre = {
    paragraphs: [
      `Backed by decades of experience in customer satisfaction, it’s no
    surprise we’re highly rated on both Google and HomeStars for our
    commitment to excellence. A large part of our success comes down to our exceptional talent and
    commitment to leadership. The quality of our work and the experience
    we provide you are of the utmost importance. Each member of our staff is an integral part of our team – we never
    use subcontractors! This allows us to facilitate quality control at a grassroots level on
    all aspects of your renovations, allowing us to deliver the final
    result on time, within budget, and in superior quality.`,
    ],
    // paragraphs: (
    //   <>
    //     <p>
    //       Backed by decades of experience in customer satisfaction, it’s no
    //       surprise we’re highly rated on both Google and HomeStars for our
    //       commitment to excellence.
    //     </p>
    //     <p>
    //       A large part of our success comes down to our exceptional talent and
    //       commitment to leadership. The quality of our work and the experience
    //       we provide you are of the utmost importance.
    //     </p>
    //     <p>
    //       Each member of our staff is an integral part of our team – we never
    //       use subcontractors!
    //     </p>
    //     <p>
    //       This allows us to facilitate quality control at a grassroots level on
    //       all aspects of your renovations, allowing us to deliver the final
    //       result on time, within budget, and in superior quality.
    //     </p>
    //   </>
    // ),
  };
  const ourValues = {
    btnText: "APPLY TO JOIN OUR TEAM",
  };
  return (
    <>
      <TopSection subtitle="Join Our Team" btnText="Apply Today" />
      <Content {...contentData} />
      <HowWeStarted btnText={ourValues.btnText} />
      <WhoWeAre paragraphs={whoWeAre.paragraphs} />
      <OurValues btnText={ourValues.btnText} />
      <Experience {...experienceInfo} />

      {/* <section>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingBottom: "0px",
              maxWidth: "980px",
              margin: "0 auto",
              textAlign: 'center',
              fontSize: '1.2em',
              lineHeight: '1.5em',
              marginTop: '0px',
              marginBottom: '120px',
            }}
          >
            <ul>
              <li>
                <b>Summer Cash Giveaway Winners</b>
              <br />
              <span style={{ color: 'rgb(6 174 222)', fontWeight: '600', fontSize: '0.9em', cursor: 'pointer', borderBottom: '2px solid rgb(6 174 222)' }} 
                onClick={() => { handler.updateStateVideo(true); }}
              >
                Click here for details
              </span> 
              </li>
            </ul>
          </div>
      </section> */}
      <Modal handler={handler} />


      <ContactInfo phone={phone} email={email} locations={locations} />
      <CallToAction {...callToActionProps} />
    </>
  );
};
