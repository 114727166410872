import React from "react";
import "../Home/windows/WindowsHomeTop.scss";

import { WindowsIntro as CallToAction } from "../OurWork/windows/WindowsIntro";
import { DoorsNav } from "../DoorsNav/DoorsNav";

import { TopIntro } from "../../Common/ServicesPages/TopIntro";
import { Features } from "../../Common/ServicesPages/Features";
// import { LatestWorks } from '../Home';
import { WindowsOurLatestWork } from "../Home/windows/WindowsOurLatestWork";

import LatestWorkOne from "../../Assets/images/windows/our-works/gallery/9e.jpg";
import LatestWorkTwo from "../../Assets/images/windows/our-works/gallery/7q.jpg";
import LatestWorkThree from "../../Assets/images/windows/our-works/gallery/8w.jpg";
import LatestWorkFour from "../../Assets/images/windows/our-works/gallery/2e.jpg";
import LatestWorkFive from "../../Assets/images/windows/our-works/gallery/4.jpg";

import bcknd_top from "../../Assets/images/windows/patio/top_background.png";
import wnds_top from "../../Assets/images/windows/vinil/wnds_top.png";
import features_bcgnd from "../../Assets/images/windows/patio/patio_calltoaction.jpg";

import VinylWindowsImg from "../../Assets/images/windows/how-it-works/vinyl-windows.png";
import EntryDoorsImg from "../../Assets/images/windows/how-it-works/entry-doors.png";
import PatioDoorsImg from "../../Assets/images/windows/how-it-works/patio-doors.png";
import GarageDoorsImg from "../../Assets/images/windows/how-it-works/garage.png";
import SizesConfigurations from "../SizesConfigurations/SizesConfigurations";
import backgroundMobile from "../../Assets/images/windows/patio/background-top-mobule-patio.png";
import FeelingBackgroundMobile from "../../Assets/images/windows/patio/background-top-mobule-patio.png";

const doorMenu = [
  {
    id: 0,
    img: VinylWindowsImg,
    alt: "vinyl-windows",
    path: "vinyl-windows",
    title: "Vinyl Windows",
  },
  {
    id: 1,
    img: EntryDoorsImg,
    alt: "entry-doors",
    path: "entry-doors",
    title: "Entry Doors",
  },
  {
    id: 2,
    img: PatioDoorsImg,
    alt: "",
    path: "patio-doors",
    title: "Patio Doors",
  },
  {
    id: 3,
    img: GarageDoorsImg,
    alt: "",
    path: "garage-doors",
    title: "Garage Doors",
    left: "-48px",
    top: "-8px",
  },
];
const TopText = {
  title: "Patio Doors",
  subtitle: `Welcome ample amounts of light into your home and elevate your backyard access with a durable, energy-efficient patio door.`,
  subtitle2: `Patio doors have changed over the years and no longer restrict your choices to one standard style. Our modern designs ensure you 
  have an impressive selection of options, including sizing, colour, and opening configuration for endless possibilities.`,
};
const FeaturesProps = {
  title: "Key Features",
  hideButton: true,
  position: "center",
  containerClass: "patio",
  checkList: [
    "Various standard paint colours",
    "Colour matching program",
    "Anti-slam design",
    "Insulated glass",
    "Full vinyl mechanical frame",
    // "100’s of Standard Paint Colours (Colour Matching is also available)",
    // "Standard Twin Point Lock, With Anti-Slam Feature, & Designer Euro-Style Handle, with Interior and Exterior Pulls",
    // "10 Wood Grain Coatings to Choose From Oversized Mechanically Fastened Sashes",
  ],
  checkList2: [
    "Durable design to withstand harsh Canadian weather",
    "Efficient installation",
    "Installed within your budget",
    "No hidden or surprise costs",
    "Backed by decades of customer satisfaction",
    // "Interior and Exterior Pocket Covers to Mask Installation Screws",
    // "And Finally, The Most Comprehensive Warranty in the Business",
    // "Decades of customer satisfaction",
  ],
};

const latestWorksItems = [
  LatestWorkOne,
  LatestWorkTwo,
  LatestWorkThree,
  LatestWorkFour,
  LatestWorkFive,
];

const PatioDoors = (props) => {
  return (
    <>
      <section className="windowsHomeTop">
        <DoorsNav doorMenu={doorMenu} active={"patio-doors"} />
      </section>
      <TopIntro
        backgroundImage={bcknd_top}
        backgroundSm={bcknd_top}
        backgroundMobile={backgroundMobile}
        {...TopText}
      />
      <Features {...FeaturesProps} />
      <>
        <SizesConfigurations />
        <div style={{ padding: "50px 0 90px 0" }}>
          <WindowsOurLatestWork
            items={latestWorksItems}
            title={"Our Work"}
            hideButton={true}
          />
        </div>
      </>
      <CallToAction
        backgroundImageIntro={features_bcgnd}
        backgrounMobileIntro={FeelingBackgroundMobile}
      />
    </>
  );
};

export default PatioDoors;
