import React from 'react';
import { Helmet } from "react-helmet";

import { 
    WarrantyAdvanced
} from '../../Containers/Warranty'

export const WarrantyPage = (props) => {
  
  return (
    <>
        <Helmet>
          <title>{process.env.REACT_APP_TITLE_WARRANTY}</title>
        </Helmet>
      <WarrantyAdvanced />
    </>
  );
}