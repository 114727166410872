import React from "react";
import { DoorsNav } from "../../DoorsNav/DoorsNav";
import { TopIntro } from "../../../Common/ServicesPages/TopIntro";
import { Features } from "../../../Common/ServicesPages/Features";
import { WindowsIntro as CallToAction } from "../../OurWork/windows/WindowsIntro";
import { TypesOfWindows } from "../../HowItWorks/windows/TypesOfWindows";

import Insulatiom from "../../../Assets/images/roof/home/insulation.png";
import Siding from "../../../Assets/images/roof/home/Siding.png";
import Skylights from "../../../Assets/images/roof/home/skylights.png";
import SoffitFascia from "../../../Assets/images/roof/home/soffit-fascia.png";
import RoofLeak from "../../../Assets/images/roof/home/roof-leak-repairs.png";
import NewRoofing from "../../../Assets/images/roof/home/new-roofing.png";
import bcknd_top from "../../../Assets/images/roof/leakRepairs/leakRepairs-topBackg.png";

import RoofRepaing from "../../../Assets/images/roof/leakRepairs/roofRepair.png";
import RoofRefresher from "../../../Assets/images/roof/leakRepairs/roofRefresher.png";
import ReoofInspections from "../../../Assets/images/roof/leakRepairs/roofInspections.png";

import calltoaction_bcknd from "../../../Assets/images/roof/leakRepairs/leakBottomBackg.png";
import features_bcknd from "../../../Assets/images/roof/leakRepairs/ourRoofingServices-backg.png";
import backgroundMobile from "../../../Assets/images/roof/leakRepairs/topBackgMobile.png";
import servicesMobile from "../../../Assets/images/roof/leakRepairs/ourRoofingServices.png";
import FeelingBackgroundMobile from "../../../Assets/images/roof/leakRepairs/bottomBackgMobile.png";

const doorMenu = [
  {
    id: 0,
    img: NewRoofing,
    alt: "NewRoofing",
    path: "new-roofing",
    title: "New Roofing",
  },
  {
    id: 1,
    img: RoofLeak,
    alt: "RoofLeakRepairs",
    path: "roof-leak-repairs",
    title: "Roof Leak Repairs",
  },
  {
    id: 2,
    img: Insulatiom,
    alt: "Insulatiom",
    path: "insulation",
    title: "Insulation",
  },
  {
    id: 3,
    img: SoffitFascia,
    alt: "SoffitFascia",
    path: "soffit-fascia-eavestrought",
    title: "Soffit, Fascia & Eavestrough",
    // left: "-48px",
    // top: "-8px",
  },
  {
    id: 4,
    img: Skylights,
    alt: "Skylights",
    path: "skylights",
    title: "Skylights",
    // left: "-48px",
    // top: "-8px",
  },
  // {
  //   id: 5,
  //   img: Siding,
  //   alt: "Siding",
  //   path: "siding",
  //   title: "Siding",
  //   // left: "-48px",
  //   // top: "-8px",
  // },
];
const className = "roof";
const TopText = {
  title: "Leak Repair Services",
  subtitle: `Are you experiencing a leak in your roof? To help protect your investment, reduce further damage and keep your family safe, a swift repair is essential. At AGM Renovations, we understand the importance of fixing a roof leak quickly and diligently.`,
  subtitle2: `To get started, contact our team of roof repair specialists today or request a free quote. One of our experienced and personable experts will be pleased to help you.`,
  btnTxt: "REQUEST LEAK REPAIR",
};

const FeaturesText = {
  title: "Our Roofing Services",
  containerClass: "roof",
  subtitle: "AGM Renovations Guarantees:",
  btntxt: "GET FREE QUOTE",
  checkList: [
    "Full roof inspection and free quote",
    "Transparent with budget and scope of work",
    "Work is started and completed on time",
    "Communication throughout the renovation",
    "Quality craftsmanship, backed by years of experience",
    // " Lifetime Warranty & Satisfaction Guaranteed",
    // "Friendly & Understanding Customer Service",
  ],
};
const typesWindowsData = [
  {
    id: 0,
    img: RoofRepaing,
    title: "Roof Repair",
    paragraths: [
      ` At AGM Renovations, our flat-rate roof repair services can help to
      protect your investment and reduce the risk of further damage. We know
      and understand how important it is to facilitate roof repairs as
      quickly as possible. That’s why our techs will arrive at your property
      with everything they need to perform the repair.`,
      `Our repair services include:`,
    ],
    listItems: ["Leaks", "Missing shingles", "Animal damage", "Weather damage"],
    // description: (
    //   <>
    //     <p>
    //       At AGM Renovations, our flat-rate roof repair services can help to
    //       protect your investment and reduce the risk of further damage. We know
    //       and understand how important it is to facilitate roof repairs as
    //       quickly as possible. That’s why our techs will arrive at your property
    //       with everything they need to perform the repair.
    //     </p>
    //     <p>Our repair services include:</p>
    //     <ul>
    //       <li> - Leaks</li>
    //       <li> - Missing shingles</li>
    //       <li> - Animal damage</li>
    //       <li> - Weather damage</li>
    //     </ul>
    //   </>
    // ),
  },
  {
    id: 1,
    img: ReoofInspections,
    title: "Roof Inspections",

    paragraths: [
      ` Can you remember the last time your roof was inspected by a
      professional? Whether you’re experiencing a leak or would like to take
      a proactive approach, we can help. Especially because a deteriorating
      roof can lead to risky and costly problems, such as mold and mildew.`,
      ` To get started, we recommend an annual roof inspection to ensure that
      your roof is in good standing or confirm that preventative care is
      needed. As a general rule of thumb, we do suggest scheduling your roof
      inspection prior to the winter months.`,
    ],

    revert: true,
  },
  {
    id: 2,

    img: RoofRefresher,
    title: "Roof Refresher",
    paragraths: [
      ` Our Roof Refresher Service is designed to address minor repairs and
      facilitate a full inspection of your roof within the same appointment.`,
      `We arrive prepared for the following:`,
    ],
    listItems: [
      "Take photos and videos",
      "Replace missing shingles",
      "Re-seal loose or curling shingles",
      "Address leaks and re-caulk flashings",
      "Relace damaged or corroded nails",
      "Provide recommendations for additional repairs",
    ],
  },
];
const ourWorkTitle = "Roof Repair & Inspection";

const RoofLeakRepairs = () => {
  return (
    <section>
      <DoorsNav
        className={className}
        doorMenu={doorMenu}
        active="roof-leak-repairs"
      />
      <TopIntro
        backgroundImage={bcknd_top}
        backgroundSm={bcknd_top}
        backgroundMobile={backgroundMobile}
        {...TopText}
      />
      <Features
        backgroundImage={features_bcknd}
        // checkList={checkList}
        imgMobile={servicesMobile}
        {...FeaturesText}
      />
      <TypesOfWindows
        typesWindowsData={typesWindowsData}
        title={ourWorkTitle}
      />

      <CallToAction
        colorTxt={"white"}
        backgroundImageIntro={calltoaction_bcknd}
        backgrounMobileIntro={FeelingBackgroundMobile}
        textHead="Schedule Your Roof Repair Today"
        textSubHead="To get started, schedule your free consultation."
        textBtn="GET FREE QUOTE"
      />
    </section>
  );
};

export default RoofLeakRepairs;
