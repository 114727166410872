import React from "react";

import "./StepWindowType.scss";
import { useBreakPoint } from "../../../hooks/useResponsive";

export const StepWindowType = ({
  img,
  title,
  description,
  revert,
  paragraths,
  listItems,
  txtI,
}) => {
  const breakPoint = useBreakPoint();
  return (
    <>
      {breakPoint == 980 ? (
        <div className="windowsTypeContainer">
          <div className="windowsTypeContainer__img">
            <img src={img} />
          </div>
          <div className="windowsTypeContainer__txt">
            <h3>{title}</h3>
            {txtI ? <i>{txtI}</i> : null}
            {paragraths ? (
              paragraths.map((p, index) => <p key={index}>{p}</p>)
            ) : (
              <p>{description}</p>
            )}
            {listItems ? (
              <ul>
                {listItems.map((li, index) => (
                  <li key={index}> - {li}</li>
                ))}
              </ul>
            ) : null}
          </div>
        </div>
      ) : revert !== true ? (
        <div className="windowsTypeContainer">
          <div className="windowsTypeContainer__img">
            <img src={img} />
          </div>
          <div className="windowsTypeContainer__txt">
            <h3>{title}</h3>
            {txtI ? <i>{txtI}</i> : null}
            {paragraths ? (
              paragraths.map((p, index) => <p key={index}>{p}</p>)
            ) : (
              <p>{description}</p>
            )}
            {listItems ? (
              <ul>
                {listItems.map((li, index) => (
                  <li key={index}> - {li}</li>
                ))}
              </ul>
            ) : null}
          </div>
        </div>
      ) : (
        <div className="windowsTypeContainer">
          <div className="windowsTypeContainer__txt">
            <h3>{title}</h3>
            {txtI ? <i>{txtI}</i> : null}
            {paragraths ? (
              paragraths.map((p, index) => <p key={index}>{p}</p>)
            ) : (
              <p>{description}</p>
            )}
            {listItems ? (
              <ul>
                {listItems.map((li, index) => (
                  <li key={index}> - {li}</li>
                ))}
              </ul>
            ) : null}
          </div>
          <div className="windowsTypeContainer__img">
            <img src={img} />
          </div>
        </div>
      )}
    </>
  );
};
