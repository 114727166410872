import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { WindowsOurListing } from "../Home/windows/WindowsOurListing";
import "../../routes/roof/WhyAgm/whyAgm.scss";

import Refprocess from "../../Assets/images/refprocess.jpg";
import flyer from "../../Assets/images/refFlyer.png";

const Referrals = () => {

    const openRefForm = (url) => {
        window.open(url, "_blank");
    };


  return (
    <>
    <section className="about-section-22" style={{ 
      paddingBottom: "0px",
      position: 'relative',
      padding: '0px 0 20px 0',
      textAlign: 'center',
      zIndex: '1',
    }}>

    <div style={{  
      background: 'rgb(32,61,93)',
      background: 'linear-gradient(180deg, rgba(32,61,93,1) 0%, rgba(33,102,144,1) 100%)',
      width: '100%',
      overflow: 'visible',
      marginBottom: '60px',
    }}>
      <img style={{ width: '80%', maxWidth: '680px', marginTop: '60px',
        position: 'relative',
        marginBottom: '-43px',
        zIndex: '10',
       }} src={flyer}/>
    </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: "0px",
          maxWidth: "980px",
          margin: "0 auto",
        }}
      >
        {/* <br /> 
        <section style={{ paddingBottom: "20px" }} id="process">
          <div className="container">
            <h2 style={{ marginBottom: "0", paddingBottom: "0" }}>
            Refer a Friend and Get <br/>$1,000 !!!
            </h2>
            
          </div>
        </section> */}

        <img style={{ width: '100%', marginLeft: '0px' }} src={Refprocess}/>
        <br/>
        <ul className="listCont" style={{ textAlign: 'left', fontSize: '1em', width: '90%' }}>
          <li>
            <b>STEP 1:</b><span>Connect with anyone you know—friends, family, colleagues, or clients—to identify those in need of home renovation.</span>
          </li>
          <li>
          <b>STEP 2:</b> <span>Share or forward the AGM voucher to the referral to guarantee they receive an exclusive discount!</span>
          </li>
          <li>
          <b>STEP 3:</b> <span>Submit your referral (<span style={{ color: 'blue', fontWeight: '400', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => { openRefForm("https://referrals.agmrenovations.com/"); }}>click here</span>) to get started</span>
          </li>
          <li>
          <b>STEP 4:</b> <span>The person you refer will receive up to $5,000 OFF on their project!</span> 
          </li>
          <li>
          <b>STEP 5:</b> <span>Claim your referral reward immediately upon the commencement of your referral's project. <span style={{ textDecoration: 'underline', color: 'blue', fontWeight: '400', cursor: 'pointer' }} onClick={() => { openRefForm("https://www.fastfieldwebforms.com/us/webforms/5df63897-10ce-44c8-97e7-bfee6fb04c18?fffcb=1701518339759"); }}>click here</span> to submit your invoice: {`($1,000 – basements/bathrooms/kitchens, $250 – any other full project type)`}</span> 
          </li>
          {/* <li>
          <b>STEP 6:</b>  <span>To submit your invoice for the referral reward, visit: <span style={{ color: '#333', fontWeight: '700', cursor: 'pointer' }} onClick={() => { openRefForm("https://tinyurl.com/AGMReferral"); }}>AGMrenovations.com/invoice</span><br/>(always confirm with your referee that the project has started before submitting your invoice).</span> 
          </li> */}
          <li>
          <b>STEP 6:</b> <span>Your referee will receive top-quality renovations at an unbeatable price—Guaranteed!</span> 
          </li>
        </ul>
        <br /><br />
      
          <div className="container">
            <h4 style={{ fontSize: '2em', marginBottom: "20px" }}>
            Refer with Confidence!
            </h4>
            <p>The more clients you refer to us, the greater your rewards.<br/> There's NO limit on project referrals!</p>
          </div>
          <br />
        <div style={{ cursor: 'pointer' }} onClick={()=>{ window.open("https://referrals.agmrenovations.com"); }} className="blue-btn desktop-btn">
          Get Started
        </div>
        
      </div>
    </section>
    </>
  );
};

export default Referrals;
