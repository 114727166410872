// import React from "react";
// import { Link } from "react-router-dom";
// import "./Features.scss";
// export const Features = ({ checkList }) => {
//   return (
//     <section className="readySterted vinil">
//       <div className="content">
//         <h2>Ready To Get Started?</h2>
//         <h3>AGM Renovations Guarantees</h3>
//         <ul className="contentList">
//           {checkList.map((item) => (
//             <li className="contentList__Item">
//               <img src={Checked} />
//               <p>{item}</p>
//             </li>
//           ))}
//         </ul>
//         <Link to="/get-started" className="blue-dark-windows-btn">
//             <button>Get Free Quote</button>
//         </Link>

//       </div>
//     </section>
//   );
// };
import React from "react";
import { Link } from "react-router-dom";
import "./Features.scss";
import Checked from "../../Assets/images/windows/home/checked-img.png";
import CheckedWhite from "../../Assets/images/windows/vinil/checked-white.png";
import { useBreakPoint } from "../../hooks/useResponsive";

export const Features = ({
  checkList,
  containerClass,
  checkList2,
  subtitle,
  title,
  backgroundImage,
  imageProps,
  hideButton,
  position,
  backgroundSize,
  imgMobile,
  btntxt,
}) => {
  const breakPoint = useBreakPoint();

  return breakPoint !== 980 ? (
    <section
      className="home-start"
      style={{
        backgroundImage: `url(${backgroundImage}) ${
          imageProps && imageProps.gradient ? ", " + imageProps.gradient : ""
        }`,
        backgroundColor: "#19579b",
        backgroundPosition:
          imageProps && imageProps.position ? imageProps.position : "left",
        backgroundSize: backgroundSize ? backgroundSize : null,
      }}
    >
      <div
        style={position ? { justifyContent: "center" } : {}}
        className="d-flex checkmark-box container"
      >
        <div className={"cnt" + " " + (containerClass || "")}>
          <h2 style={checkList2 ? { textAlign: "center" } : {}}>{title}</h2>
          {subtitle && <p>{subtitle}</p>}
          <div
            className="listContainerDesktop"
            style={checkList2 ? { display: "flex" } : {}}
          >
            <ul>
              {checkList.map((item, index) => (
                <li key={index}>
                  <div className="imgWrapper">
                    <img src={CheckedWhite} className="float-left" />
                  </div>
                  <b>{item}</b>
                </li>
              ))}
            </ul>

            {/* {checkList2 && (
              <ul>
                {checkList2.map((item, index) => (
                  <li key={index}>
                    <div className="imgWrapper">
                      <img src={Checked} className="float-left" />
                    </div>
                    <b>{item}</b>
                  </li>
                ))}
              </ul>
            )} */}
          </div>
          {!hideButton && (
            <div className="cnt centered-btn">
              <Link to="/get-started" className="blue-btn desktop-btn windows">
                {btntxt ? btntxt : "Get Your Free Quote"}
              </Link>
            </div>
          )}
        </div>
      </div>
    </section>
  ) : (
    <section
      className="mobileSection"
      style={{
        backgroundColor: "#19579b",
        backgroundImage: `url(${imgMobile})`,
      }}
    >
      <div
        style={position ? { justifyContent: "center" } : {}}
        className="d-flex checkmark-box container"
      >
        <div className={"cnt" + " " + (containerClass || "")}>
          <h2 style={checkList2 ? { textAlign: "center" } : {}}>{title}</h2>
          {subtitle && <p>{subtitle}</p>}
          <div
            className="listContainer"
            style={
              checkList2 ? { display: "flex", flexDirection: "column" } : {}
            }
          >
            <ul>
              {checkList.map((item, index) => (
                <li key={index}>
                  <div className="imgWrapper">
                    <img src={CheckedWhite} className="float-left" />
                  </div>
                  <b>{item}</b>
                </li>
              ))}
            </ul>
          </div>
          {!hideButton && (
            <div className="cnt centered-btn">
              <Link to="/get-started" className="blue-btn desktop-btn windows">
                {btntxt ? btntxt : "Get Your Free Quote"}
              </Link>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
