import configData from "./Config";
import AWS from "aws-sdk";
import fs from "fs";
function Upload(newFile) {
  return new Promise((res, rej) => {
    let s3 = new AWS.S3({
      params: {
        Bucket: configData.FILE_BUCKET_NAME,
      },
      signatureVersion: "v4",
    });
    AWS.config.update({
      accessKeyId: configData.AWS_ACCESS_KEY_ID,
      secretAccessKey: configData.AWS_SECRET_ACCESS_KEY,
      region: configData.AWS_REGION,
    });
    console.log("newFile.name " + newFile.name);
    var params = {
      Bucket: configData.FILE_BUCKET_NAME,
      Key: `${newFile.name}`,
      ACL: "public-read",
      Body: newFile,
    };
    s3.upload(params, function (err, data) {
      if (err) {
        rej(err);
      } else {
        res(true);
      }
    });
  });
}

export default Upload;
