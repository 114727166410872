import React from "react";

import "./WindowsTopSection.scss";

export const WindowsTopOurWork = () => {
  return (
    <section className="ourWorkTopSection">
      <h2> Our Work</h2>
      <h3>GET INSPIRED</h3>
    </section>
  );
};
