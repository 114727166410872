import React from "react";
import { Link } from "react-router-dom";

import "./DoorsNav.scss";

export const DoorsNav = ({ doorMenu, active, className }) => {
  return (
    <section className="topDoors_Wrapper">
      <div className={`topDoors ${className ? " " + className : ""}`}>
        {doorMenu.map((item, ind) => (
          <div
            key={item.id}
            className={`topDoors__item${
              item.path && item.path === active ? " active" : ""
            } item${ind}`}
          >
            <Link to={item.path}>
              <img src={item.img} alt={item.alt} />
              <h4 style={{ color: "#fff", cursor: "pointer" }}>{item.title}</h4>
            </Link>
          </div>
        ))}
      </div>
    </section>
  );
};
