import React from "react";

import { VideoTestimonials, WorksGallery } from "../../Containers/OurWork";
import { WindowsIntro } from "../../Containers/OurWork/windows/WindowsIntro";
import { WindowsTopOurWork } from "../../Containers/OurWork/windows/WindowsTopOurWork";
import { DoorsNav } from "../../Containers/DoorsNav/DoorsNav";

import MobileBottomBAckg from "../../Assets/images/roof/ourWork/ourWorkBottomBAckg.png";
import backgroundImageIntro from "../../Assets/images/roof/ourWork/ourWork_Bottom_backg.png";
import Insulatiom from "../../Assets/images/roof/home/insulation.png";
// import Siding from "../../Assets/images/roof/home/Siding.png";
import Skylights from "../../Assets/images/roof/home/skylights.png";
import SoffitFascia from "../../Assets/images/roof/home/soffit-fascia.png";
import RoofLeakRepairs from "../../Assets/images/roof/home/roof-leak-repairs.png";
import NewRoofing from "../../Assets/images/roof/home/new-roofing.png";

import prjvprv1 from "../../Assets/images/tstm1.jpg";
import prjvprv2 from "../../Assets/images/tstm2.jpg";
import prjvprv3 from "../../Assets/images/tstm3.jpg";
import prjvprv4 from "../../Assets/images/tstm4.jpg";
import prjvprv5 from "../../Assets/images/tstm5.jpg";
import prjvprv6 from "../../Assets/images/tstm6.jpg";

const roofMenu = [
  {
    id: 0,
    img: NewRoofing,
    alt: "NewRoofing",
    path: "new-roofing",
    title: "New Roofing",
  },
  {
    id: 1,
    img: RoofLeakRepairs,
    alt: "RoofLeakRepairs",
    path: "/roof-leak-repairs",
    title: "Roof Leak Repairs",
  },
  {
    id: 2,
    img: Insulatiom,
    alt: "Insulatiom",
    path: "insulation",
    title: "Insulation",
  },
  {
    id: 3,
    img: SoffitFascia,
    alt: "SoffitFascia",
    path: "soffit-fascia-eavestrought",
    title: "Soffit, Fascia & Eavestrough",
    // left: "-48px",
    // top: "-8px",
  },
  {
    id: 4,
    img: Skylights,
    alt: "Skylights",
    path: "skylights",
    title: "Skylights",
    // left: "-48px",
    // top: "-8px",
  },
  // {
  //   id: 5,
  //   img: Siding,
  //   alt: "Siding",
  //   path: "siding",
  //   title: "Siding",
  //   // left: "-48px",
  //   // top: "-8px",
  // },
];
const className = "roof";
export const OurWorkPage = (props) => {
  const importAll = (r) => {
    return r.keys().map(r);
  };

  const galleryImages = importAll(
    require.context(
      "../../Assets/images/roof/ourWorkGalery",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );

  //* Get started using our 3D Creator or call <a href='tel:{phone}'>{phone}</a> to get a free quote

  return (
    <>
      <DoorsNav className={className} doorMenu={roofMenu} />
      <WindowsTopOurWork />
      <VideoTestimonials items={[
            { videoId: '1ch6DPAXZYk', previewImage: prjvprv6 },
            { videoId: '7F4ZcDON7AU', previewImage: prjvprv1 },
            { videoId: '5ZTVhlbCo5w', previewImage: prjvprv2 },
            { videoId: 'z9nRWBHOmwE', previewImage: prjvprv3 },
            { videoId: 'O8boL1uOdCM', previewImage: prjvprv4 },
            { videoId: 'ciBjGrZhi-8', previewImage: prjvprv5 },
       ]} />
      <WorksGallery images={galleryImages} />
      <WindowsIntro
        backgrounMobileIntro={MobileBottomBAckg}
        backgroundImageIntro={backgroundImageIntro}
      />
    </>
  );
};
