import React from "react";
import { Link } from "react-router-dom";
import { useBreakPoint } from "../../../hooks/useResponsive";
import DefaultBackground from "../../../Assets/images/windows/ourWorks/ourWork-bottom-intro.png";
import "./WindowsIntro.scss";
import { height } from "@mui/system";

export const WindowsIntro = ({
  backgroundImageIntro,
  backgrounMobileIntro,
  colorTxt,
  textHead,
  textSubHead,
  textBtn,
}) => {
  const breakPoint = useBreakPoint();
  const background =
    breakPoint === 980
      ? backgrounMobileIntro
      : backgroundImageIntro
      ? backgroundImageIntro
      : DefaultBackground;
  return (
    <section
      className="windowsIntro"
      style={{
        backgroundImage: `url(${background}`,
        height: background !== undefined ? "700px" : "auto",
        backgroundSize: "cover",
      }}
    >
      <div className="windows__content">
        <h2 style={{ color: colorTxt ? colorTxt : "" }}>
          {textHead ? textHead : "Feeling Inspired?"}
        </h2>
        <h3 style={{ color: colorTxt ? colorTxt : "" }}>
          {textSubHead ? textSubHead : "Schedule your free consultation today!"}
        </h3>
        <Link to="/get-started" className="blue-dark-windows-btn">
          <button>{textBtn ? textBtn : "BOOK FREE CONSULTATION"}</button>
        </Link>
      </div>
    </section>
  );
};
