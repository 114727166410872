import React from "react";
import { DoorsNav } from "../../DoorsNav/DoorsNav";
import { TopIntro } from "../../../Common/ServicesPages/TopIntro";
import { Features } from "../../../Common/ServicesPages/Features";
import { TypesOfWindows } from "../../HowItWorks/windows/TypesOfWindows";
import { WindowsIntro as CallToAction } from "../../OurWork/windows/WindowsIntro";

import Insulatiom from "../../../Assets/images/roof/home/insulation.png";
import Siding from "../../../Assets/images/roof/home/Siding.png";
import Skylights from "../../../Assets/images/roof/home/skylights.png";
import SoffitFascia from "../../../Assets/images/roof/home/soffit-fascia.png";
import RoofLeakRepairs from "../../../Assets/images/roof/home/roof-leak-repairs.png";
import NewRoofing from "../../../Assets/images/roof/home/new-roofing.png";

import bcknd_top from "../../../Assets/images/roof/insulation/insulationTopBackg.png";
import features_bcknd from "../../../Assets/images/roof/insulation/insulationServices.png";
import calltoaction_bcknd from "../../../Assets/images/roof/insulation/insulationBottomBackg.png";

import Fibreglas from "../../../Assets/images/roof/insulation/fibreglass.png";
import Cellulose from "../../../Assets/images/roof/insulation/cellulose.png";
import ClosedCell from "../../../Assets/images/roof/insulation/closed-cell.png";
import backgroundMobile from "../../../Assets/images/roof/insulation/topBackgMobile.png";
import RoofServices from "../../../Assets/images/roof/insulation/servicesMobile.png";
import FeelingBackgroundMobile from "../../../Assets/images/roof/insulation/bottomBackgMobile.png";

const doorMenu = [
  {
    id: 0,
    img: NewRoofing,
    alt: "NewRoofing",
    path: "new-roofing",
    title: "New Roofing",
  },
  {
    id: 1,
    img: RoofLeakRepairs,
    alt: "RoofLeakRepairs",
    path: "/roof-leak-repairs",
    title: "Roof Leak Repairs",
  },
  {
    id: 2,
    img: Insulatiom,
    alt: "Insulatiom",
    path: "insulation",
    title: "Insulation",
  },
  {
    id: 3,
    img: SoffitFascia,
    alt: "SoffitFascia",
    path: "soffit-fascia-eavestrought",
    title: "Soffit, Fascia & Eavestrough",
  },
  {
    id: 4,
    img: Skylights,
    alt: "Skylights",
    path: "skylights",
    title: "Skylights",
    // left: "-48px",
    // top: "-8px",
  },
  // {
  //   id: 5,
  //   img: Siding,
  //   alt: "Siding",
  //   path: "siding",
  //   title: "Siding",
  //   // left: "-48px",
  //   // top: "-8px",
  // },
];
const className = "roof";

const TopText = {
  title: "Insulating Your Roof",
  subtitle: `Many homeowners and professionals will agree, there are endless benefits to insulating your home. As such, your roof should always be a top priority for professional insulation.`,
  subtitle2: `Not only can insulation help to improve the temperature regulation within your home, but it can also help to reduce the risk of insects and rodents, all while protecting your investment from the elements and more.`,
  btnTxt: "GET STARTED TODAY",
};

const FeaturesText = {
  title: "Insulation Services",
  containerClass: "roof",
  subtitle: "Your Roof Insulation Experts",
  btntxt: "GET FREE QUOTE",
  checkList: [
    "Complete, in-person inspection",
    "Transparency of the scope and budget",
    "Projects completed on time and within budget",
    "Quality craftsmanship built on years of experience",
    "No hidden fees or added costs",
    // " Lifetime Warranty & Satisfaction Guaranteed",
    // "Friendly & Understanding Customer Service",
  ],
};
const typesWindowsData = [
  {
    id: 0,
    img: Fibreglas,
    title: "Blown-In Fibreglass Insulation",
    paragraths: [
      ` Blown-in fibreglass insulation is a synthetic material which, as the
      name suggests, is blown into place. This is done using a machine for
      attics and flat roof rafter cavities. Blown-in fibreglass insulation is
      a great option for those who are looking to increase thermal efficiency
      and overall temperature regulation within their homes.`,
    ],
    // listItems: ["Leaks", "Missing shingles", "Animal damage", "Weather damage"],
    txtI: "R-Value Per Inch = Approx 3",
    // description: (
    //   <>
    //     <i> R-Value Per Inch = Approx 3 </i>
    //     <br />
    //     Blown-in fibreglass insulation is a synthetic material which, as the
    //     name suggests, is blown into place. This is done using a machine for
    //     attics and flat roof rafter cavities. Blown-in fibreglass insulation is
    //     a great option for those who are looking to increase thermal efficiency
    //     and overall temperature regulation within their homes.
    //   </>
    // ),
  },
  {
    id: 1,
    img: Cellulose,
    title: "Blown-In Cellulose Insulation",
    txtI: "R-Value Per Inch = Approx 3.5",
    paragraths: [
      ` Cellulose is an organic material that is made from shredded newspaper
    and fire retardants. The material is applied using a machine to blow
    into attic spaces and flat roof rafter cavities to help increase the
    temperature regulation and overall thermal efficiency.`,
    ],
    // description: (
    //   <>
    //     <i> R-Value Per Inch = Approx 3.5 </i> <br />

    //   </>
    // ),
    revert: true,
  },
  {
    id: 2,

    img: ClosedCell,
    title: "2-lb Closed-Cell Spray Foam Insulation",
    txtI: "R-Value Per Inch = Approx 6.5",
    paragraths: [
      `While this option tends to be more expensive to purchase and maintain,
    it most certainly provides an exceptional result. With closed-cell
    spray foam insulation, you must purchase both the insulation and the
    equipment to apply it. This product is a great option for areas that
    are difficult to reach, such as crawl spaces, duct work, garage
    ceilings, cantilevered bay windows, and rafter pockets, among others.`,
      ` Please keep in mind that the R value of this material will increase
    with each inch of added spray foam material.`,
    ],
    // description: (
    //   <>
    //     <i> R-Value Per Inch = Approx 6.5 </i> <br />
    //     <p>
    //       While this option tends to be more expensive to purchase and maintain,
    //       it most certainly provides an exceptional result. With closed-cell
    //       spray foam insulation, you must purchase both the insulation and the
    //       equipment to apply it. This product is a great option for areas that
    //       are difficult to reach, such as crawl spaces, duct work, garage
    //       ceilings, cantilevered bay windows, and rafter pockets, among others.
    //     </p>
    //     <p>
    //       Please keep in mind that the R value of this material will increase
    //       with each inch of added spray foam material.
    //     </p>
    //   </>
    // ),
  },
];
const ourWorkTitle = "Insulation & Ventilation";
const Insulation = () => {
  return (
    <section>
      <DoorsNav className={className} doorMenu={doorMenu} active="insulation" />
      <TopIntro
        backgroundImage={bcknd_top}
        backgroundSm={bcknd_top}
        backgroundMobile={backgroundMobile}
        {...TopText}
      />
      <Features
        backgroundImage={features_bcknd}
        // checkList={checkList}
        imgMobile={RoofServices}
        {...FeaturesText}
      />
      <TypesOfWindows
        typesWindowsData={typesWindowsData}
        title={ourWorkTitle}
      />

      <CallToAction
        backgroundImageIntro={calltoaction_bcknd}
        backgrounMobileIntro={FeelingBackgroundMobile}
        textBtn="GET STARTED"
      />
    </section>
  );
};

export default Insulation;
