import React from "react";

import "./ProductSpecification.scss";

export default function ProductSpecification() {
  return (
    <section className="productSection">
      <h2>Product Specifications</h2>
      <div className="productTable">
        <table>
          <thead>
            <tr className="tableHeader">
              <td>Product</td>
              <td>U-Factor</td>
              <td>
                Solar Heat Gain
                <br />
                (SHGC)
              </td>
              <td>Energy Rating</td>
              <td>
                U-Factor <br />
                (BTU/H/FT2-°F)
              </td>
              <td>
                Visible <br />
                Transmittance
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Awning Windows</td>
              <td>0.97</td>
              <td>0.38</td>
              <td>41/-</td>
              <td>0.17</td>
              <td>0.45</td>
            </tr>
            <tr>
              <td>Casement Windows (Fixed)</td>
              <td>0.91</td>
              <td>0.45</td>
              <td>46/-</td>
              <td>0.16</td>
              <td>0.53</td>
            </tr>
            <tr>
              <td>Casement Windows (Picture)</td>
              <td>0.85</td>
              <td>0.5</td>
              <td>50</td>
              <td>0.15</td>
              <td>0.6</td>
            </tr>
            <tr>
              <td>Casement Windows (Operable)</td>
              <td>0.97</td>
              <td>0.38</td>
              <td>40</td>
              <td>0.17</td>
              <td>0.45</td>
            </tr>
            <tr>
              <td>Single Slider Windows</td>
              <td>1.02</td>
              <td>0.44</td>
              <td>39</td>
              <td>0.19</td>
              <td>0.52</td>
            </tr>
            <tr>
              <td>Double Slider Windows</td>
              <td>1.02</td>
              <td>0.43</td>
              <td>39</td>
              <td>0.19</td>
              <td>0.51</td>
            </tr>
            <tr>
              <td>Single Hung Windows</td>
              <td>1.02</td>
              <td>0.44</td>
              <td>42</td>
              <td>0.18</td>
              <td>0.52</td>
            </tr>
            <tr>
              <td>Double Hung Windows</td>
              <td>1.02</td>
              <td>0.44</td>
              <td>42</td>
              <td>0.18</td>
              <td>0.52</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
}
