
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { WindowsOurListing } from "../Home/windows/WindowsOurListing";
import Pagination from '@mui/material/Pagination';
import "../../routes/roof/WhyAgm/whyAgm.scss";

import filesFetcher from '../../Services/filesFetcher';
const defLinks = [
    "https://ocnjdaily.com/agm-renovations-reviews-latest-trends-basement-remodeling/",
    "https://somerspoint.com/2023/09/25/agm-renovations-reviews-new-3d-bathroom-creator/",
    "https://seaislenews.com/agm-renovations-reviews-kitchen-renos-creating-heart-home/",
    "https://www.prweb.com/releases/agm-renovations-announces-the-exciting-debut-of-showroom-on-wheels-301942058.html",
    "https://www.prweb.com/releases/agm-renovations-announces-new-agm-renovations-program-to-help-homeowners-offset-their-high-mortgage-payment-301954208.html",
];

const openPage = (url) => {
    window.open(url);
};

const cloudStorageUrl = 'https://agm-landing.s3.us-east-2.amazonaws.com/websites/articlesImages';

const Blog = () => {

    const [currentArticle, setArticleIndex] = useState(1);
    const [pages, setPages] = useState([]);

    const changeArticle = (ind) => {
        setArticleIndex(ind);
    };

    useEffect(() => {
        const fetchServices = async () => {
          const pages = await filesFetcher.fetchArticles();
          if (pages && pages.articles) setPages(pages.articles);
        };
        fetchServices();
    }, []);

  return (
    <>
    <section className="about-section-2" style={{ paddingBottom: "0px" }} >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: "0px",
          maxWidth: "980px",
          margin: "0 auto",
        }}
      >
        <br /> 
        <section style={{ paddingBottom: "20px" }} id="process">
          <div className="container">
            <h2 style={{ marginBottom: "0", paddingBottom: "0" }}>
                Media
            </h2>
          </div>
        </section>

        </div>
    </section>
    <br/><br/>
    <br/><br/>
    <section className="about-section-22" style={{ paddingBottom: "0px" }} >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: "0px",
          maxWidth: "980px",
          margin: "0 auto",
        }}
      >
        { pages.length 
            ? <>
              <Pagination count={pages.length} page={currentArticle} onChange={(e, value)=>{ 
                setArticleIndex(value); 
              }} />
              <br/>
              {pages.map((item, index) => {
                return(
                    <div key={index} style={{ display: index === (currentArticle-1) ? 'block' : 'none', maxWidth: '700px' }} >
                        <img
                            width="100%"
                            height="100%"
                            src={cloudStorageUrl + item.image}
                        />
                        <div onClick={()=> { openPage(item.url); }} style={{ color: 'blue', textAlign: 'center', textDecoration: 'underline', fontWeight: '700', cursor: 'pointer' }} className="desktop-btn">
                            Open Full Article
                        </div>
                    </div>
                );
              })}
            </> 
            : <>{defLinks.map((url, index) => (<a key={index} href={url}></a>))}</>
          }

        {/* <ul style={{ textAlign: 'left', fontSize: '1em' }}>
          <li>
            <b>STEP 1:</b><br /> Connect with anyone you know—friends, family, colleagues, or clients—to identify those in need of home renovation. 
          </li>
          <br />
          <li>
          <b>STEP 2:</b><br /> Share or forward the AGM voucher to the referral to guarantee they receive an exclusive discount! 
          </li>
          <br />
          <li>
          <b>STEP 3:</b><br /> Use this link to submit your referral (<span style={{ color: 'blue', fontWeight: '400', cursor: 'pointer' }} onClick={() => { openRefForm("https://referrals.agmrenovations.com/"); }}>click on this link</span> to get started)  
          </li>
          <br/>
          <li>
          <b>STEP 4:</b><br /> The person you refer will receive up to $5,000 OFF on their project! 
          </li>
        </ul> */}

      </div>
    </section>
    </>
  );
};

export default Blog;
