import React from "react";
import { Link } from "react-router-dom";
import "./WindowsRedyStarted.scss";

import Checked from "../../../Assets/images/windows/home/checked-img.png";
import Image from "../../../Assets/images/roof/home/bottomRooff.png";

export const WindowsRedyStarted = ({ checkList }) => {
  return (
    <section className="readySterted">
      <div className="content imageContainer">
        <img src={Image} />
      </div>
      <div className="content">
        <h2>Time to Get Started?</h2>
        <h3>The AGM Difference</h3>
        <ul className="contentList">
          {checkList.map((item, index) => (
            <li key={index} className="contentList__Item">
              <img src={Checked} />
              <p>{item}</p>
            </li>
          ))}
        </ul>
        <Link to="/get-started" className="blue-dark-windows-btn">
          <button>GET FREE QUOTE</button>
        </Link>
      </div>
    </section>
  );
};
