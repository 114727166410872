import React from "react";
import "../Home/windows/WindowsHomeTop.scss";

import { WindowsIntro as CallToAction } from "../OurWork/windows/WindowsIntro";
import { DoorsNav } from "../DoorsNav/DoorsNav";

import { TopIntro } from "../../Common/ServicesPages/TopIntro";
import { Features } from "../../Common/ServicesPages/Features";
import OurColection from "../OurColection/OurColection";

import features_bcknd from "../../Assets/images/windows/entry/entry_features.jpg";
import bcknd_top from "../../Assets/images/windows/entry/top_background.png";
import alltoaction_bcknd from "../../Assets/images/windows/entry/entry_calltoaction.jpg";
import backgroundTopSm from "../../Assets/images/windows/entry/doors-background-sm.png";

import VinylWindowsImg from "../../Assets/images/windows/how-it-works/vinyl-windows.png";
import EntryDoorsImg from "../../Assets/images/windows/how-it-works/entry-doors.png";
import PatioDoorsImg from "../../Assets/images/windows/how-it-works/patio-doors.png";
import GarageDoorsImg from "../../Assets/images/windows/how-it-works/garage.png";

import Brixton from "../../Assets/images/windows/entry/1 brixton.jpg";
import Mundo from "../../Assets/images/windows/entry/2 mundo.jpg";
import Vog from "../../Assets/images/windows/entry/3 vog.jpg";
import Victoria_Shaker from "../../Assets/images/windows/entry/4 victoria-shaker.jpg";
import Soho from "../../Assets/images/windows/entry/5 soho.jpg";
import Orieans from "../../Assets/images/windows/entry/6 orleans.jpg";
import Linea from "../../Assets/images/windows/entry/7 linea.jpg";
import Sydney from "../../Assets/images/windows/entry/8 sydney.jpg";

import oso from "../../Assets/images/windows/entry/9 oso.jpg";
import londonBlue from "../../Assets/images/windows/entry/10 london-blue.jpg";
import uno from "../../Assets/images/windows/entry/11 uno.jpg";
import jasper from "../../Assets/images/windows/entry/12 jasper.jpg";
import moncton from "../../Assets/images/windows/entry/13 moncton.jpg";
import panelRed from "../../Assets/images/windows/entry/14 panel-red.jpg";
import panelWhite from "../../Assets/images/windows/entry/15 panel-white.jpg";
import panelBrown from "../../Assets/images/windows/entry/16 panel-brown.jpg";

import LondonRed from "../../Assets/images/windows/entry/17 london-red.jpg";
import Victoria from "../../Assets/images/windows/entry/18 victoria.jpg";
import OrleansBrown from "../../Assets/images/windows/entry/19 orleans-brown.jpg";
import Richmond from "../../Assets/images/windows/entry/20 richmond.jpg";
import Flush from "../../Assets/images/windows/entry/21 flush.jpg";
import PanelCamberTop from "../../Assets/images/windows/entry/22 panel-camber-top.jpg";
import PanelScrollTop from "../../Assets/images/windows/entry/23 panel-scroll-top.jpg";
import PanelBlanTop from "../../Assets/images/windows/entry/24 panel-blank-top.jpg";
import PanelCamberTopFiberglass from "../../Assets/images/windows/entry/25 panel-camber-top-fiberglass.jpg";
import PanelPlankedXamberTopFiberglass from "../../Assets/images/windows/entry/26 panel-planked-camber-top-fiberglass.jpg";
import PanelPlankedXamberTop from "../../Assets/images/windows/entry/27 panel-planked-camber-top.jpg";
import PanelBrown2 from "../../Assets/images/windows/entry/28 panel-brown.jpg";
import backgroundMobile from "../../Assets/images/windows/entry/background-top-mobile-entry.png";
import WindowsSecondTop from "../../Assets/images/windows/entry/second-top-mobile-entry.png";
import FeelingBackgroundMobile from "../../Assets/images/windows/entry/feeling-background-mobile-entry.png";

const checkList = [
  "Completing our projects on time ",
  "Completing our projects within budget",
  "No added or hidden costs",
  "Decades of customer satisfaction",
];
const doorMenu = [
  {
    id: 0,
    img: VinylWindowsImg,
    alt: "vinyl-windows",
    path: "vinyl-windows",
    title: "Vinyl Windows",
  },
  {
    id: 1,
    img: EntryDoorsImg,
    alt: "entry-doors",
    path: "entry-doors",
    title: "Entry Doors",
  },
  {
    id: 2,
    img: PatioDoorsImg,
    alt: "",
    path: "patio-doors",
    title: "Patio Doors",
  },
  {
    id: 3,
    img: GarageDoorsImg,
    alt: "",
    path: "garage-doors",
    title: "Garage Doors",
    left: "-48px",
    top: "-8px",
  },
];
const TopText = {
  title: "Entry Doors",
  subtitle: `Create a show-stopping front entrance with an updated door that adds vibrant colour to the exterior of your home or enhances your porch with a classic, muted design.`,
  subtitle2: `We understand that every home has its own unique style, and we wanted to ensure we had a wide variety of options to easily meet your needs. We provide an impressive selection of entryway doors that are exceptionally designed with safety and style in mind!`,
};
const FeaturesText = {
  title: "The Best Entryway Doors in Ontario",
  containerClass: "doors",
  subtitle:
    "At AGM Renovations, we value your experience above all else and ensure your satisfaction through four guarantees.",
  checkList: [
    "Completing projects on time",
    "Keeping projects within budget",
    "No hidden or surprise costs",
    "Decades of customer satisfaction",
    // " Lifetime Warranty & Satisfaction Guaranteed",
    // "Friendly & Understanding Customer Service",
  ],
};

const OurWorks = [
  { id: 0, img: Brixton, alt: "Brixton", title: "Brixton" },
  { id: 1, img: Mundo, alt: "Mundo", title: "Mundo" },
  { id: 2, img: Vog, alt: "Vog", title: "Vog" },
  {
    id: 3,
    img: Victoria_Shaker,
    alt: "Victoria Shaker",
    title: "Victoria Shaker",
  },
  { id: 4, img: Soho, alt: "Soho", title: "Soho" },
  { id: 5, img: Orieans, alt: "Orieans", title: "Orieans" },
  { id: 6, img: Linea, alt: "Linea", title: "Linea" },
  { id: 7, img: Sydney, alt: "Sydney", title: "Sydney" },
  { id: 8, img: oso, alt: "oso", title: "Oso" },
  { id: 9, img: londonBlue, alt: "londonBlue", title: "London Blue" },
  { id: 10, img: uno, alt: "uno", title: "Uno" },
  { id: 11, img: jasper, alt: "jasper", title: "Jasper" },
  { id: 12, img: moncton, alt: "moncton", title: "Moncton" },
  { id: 13, img: panelRed, alt: "panelRed", title: "Panel Red" },
  { id: 14, img: panelWhite, alt: "panelWhite", title: "Panel White" },
  { id: 15, img: panelBrown, alt: "panelBrown", title: "Panel Brown" },
  { id: 16, img: LondonRed, alt: "LondonRed", title: "London Red" },
  { id: 17, img: Victoria, alt: "Victoria", title: "Victoria" },
  { id: 18, img: OrleansBrown, alt: "OrleansBrown", title: "Orleans Brown" },
  { id: 19, img: Richmond, alt: "Richmond", title: "Richmond" },
  { id: 20, img: Flush, alt: "Flush", title: "Flush" },
  {
    id: 21,
    img: PanelCamberTop,
    alt: "PanelCamberTop",
    title: "Panel Camber Top",
  },
  {
    id: 22,
    img: PanelScrollTop,
    alt: "PanelScrollTop",
    title: "Panel Scroll Top",
  },
  { id: 23, img: PanelBlanTop, alt: "PanelBlanTop", title: "Panel BlanTop" },
  {
    id: 24,
    img: PanelCamberTopFiberglass,
    alt: "PanelCamberTopFiberglass",
    title: "Panel Camber Top Fiberglass",
  },
  {
    id: 25,
    img: PanelPlankedXamberTopFiberglass,
    alt: "PanelPlankedXamberTopFiberglass",
    title: "Panel Planked Xamber Top Fiberglass",
  },
  {
    id: 26,
    img: PanelPlankedXamberTop,
    alt: "PanelPlankedXamberTop",
    title: "Panel Planked Xamber Top",
  },
  { id: 27, img: PanelBrown2, alt: "PanelBrown2", title: "Panel Brown" },
];
const ourDoors = "Explore our doors to find your perfect match.";

const EntryDoors = (props) => {
  return (
    <>
      <section className="windowsHomeTop">
        <DoorsNav doorMenu={doorMenu} active={"entry-doors"} />
      </section>
      <TopIntro
        backgroundImage={bcknd_top}
        backgroundSm={backgroundTopSm}
        backgroundMobile={backgroundMobile}
        {...TopText}
      />
      <Features
        backgroundImage={features_bcknd}
        checkList={checkList}
        imgMobile={WindowsSecondTop}
        {...FeaturesText}
      />
      <OurColection
        subtitle={ourDoors}
        items={OurWorks}
        title={"Our Entry Doors Collection"}
      />
      <CallToAction
        backgroundImageIntro={alltoaction_bcknd}
        backgrounMobileIntro={FeelingBackgroundMobile}
      />
    </>
  );
};

export default EntryDoors;
