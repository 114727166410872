import React from 'react';
import Career from '../../Containers/Career/Career';
import { Helmet } from "react-helmet";

export const CareersPage = (props) => {
  return (
    <>
        <Helmet>
          <title>{process.env.REACT_APP_TITLE_CAREERS}</title>
        </Helmet>
      <Career />
    </>
  );
}