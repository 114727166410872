import React from "react";
import Logo from "../../../Assets/images/bath_logo.svg";
import MailLogo from "../../../Assets/images/windows/how-it-works/mail-logo.png";
import PhoneLogo from "../../../Assets/images/windows/how-it-works/phone-logo.png";
import LocationLogo from "../../../Assets/images/windows/how-it-works/location-logo.png";
import "./WindowsFooter.scss";

import facebookLogo from "../../../Assets/images/windows/footer/icon-facebook.png";
import twitterLogo from "../../../Assets/images/windows/footer/icon-twitter.png";
import instagramLogo from "../../../Assets/images/windows/footer/icon-instagram.png";
import youtubeLogo from "../../../Assets/images/windows/footer/icon-youtube.png";
import linkedinLogo from "../../../Assets/images/windows/footer/icon-linkedin.png";

const socialIcon = [
  {
    img: facebookLogo,
    alt: "facebook",
  },
  {
    img: twitterLogo,
    alt: "twitter",
  },
  {
    img: instagramLogo,
    alt: "instagram",
  },
  {
    img: youtubeLogo,
    alt: "youtube",
  },
  {
    img: linkedinLogo,
    alt: "linkedin",
  },
];

export const WindowsFooter = () => {
  return (
    <footer>
      <div className="footerWrapper">
        <div className="topSection">
          <div className="getFreeSection">
            <img src={Logo} />
            <p>
              We at AGM have simplified the renovation process for the comfort
              of our customers because we recognize the worry that goes along
              with it. We make it simple for you to acquire your freshly painted
              home and provide you with an instant quote!
            </p>
            <button className="getFreeSection__btn">Get Free Quote</button>
          </div>
          <div className="contactSection">
            <ul>
              <li>
                <img src={LocationLogo} />
                <p>4-120 Jevlan Drive. Woodbridge Ontario L4L 8G3</p>
              </li>
              <li>
                <img src={PhoneLogo} />
                <p>289-201-2608</p>
              </li>
              <li>
                <img src={MailLogo} />
                <p>service@agmrenovations.com</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="devider" />
        <div className="bottomSection">
          <div className="bottomSection__social">
            {socialIcon.map((item) => (
              <img src={item.img} alt={item.alt} />
            ))}
          </div>
          <div className="bottomSection__copyright">
            <p>
              &copy; 2022 AGM Renovations. All Rights Reserved. | Privacy Policy
              | <span className="warranty">Warranty</span>
            </p>
          </div>
          <div className="lorem">
            <p>Lorem impsu</p>
          </div>
        </div>
      </div>
    </footer>
  );
};
