import React from "react";
import { DoorsNav } from "../../DoorsNav/DoorsNav";
import "./WindowsTopSection.scss";

export const WindowsTopSection = () => {
  return (
    <section id="topWindows">
      <div className="banner">
        <h2>How It Works</h2>
        <h3>Our Roofing Process</h3>
      </div>
    </section>
  );
};
