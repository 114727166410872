import React from "react";

import { WorkSteps } from "../../Containers/HowItWorks";
import { Helmet } from "react-helmet";
import { VideoTestimonials } from "../../Containers/OurWork";

import { WindowsTopSection } from "../../Containers/HowItWorks/windows/WindowsTopSection";
import { DoorsNav } from "../../Containers/DoorsNav/DoorsNav";
import VinylWindows from "../../Assets/images/windows/how-it-works/vinyl-windows.png";
import EntryDoors from "../../Assets/images/windows/how-it-works/entry-doors.png";
import PatioDoors from "../../Assets/images/windows/how-it-works/patio-doors.png";
import GarageDoors from "../../Assets/images/windows/how-it-works/garage.png";

import stepPeopleWithTree from "../../Assets/images/hiw_step_1.png";
import stepTaplet from "../../Assets/images/hiw_step_4.png";
import stepPeopleWithLeptop from "../../Assets/images/hiw_step_6.jpg";
import stepAssemblingRoof from "../../Assets/images/roof/howItWorks/stepHowItWorks.png";

// import bayBow from "../../Assets/images/windows/how-it-works/bay-bow.png";
// import casement from "../../Assets/images/windows/how-it-works/casement.png";
// import doubleHung from "../../Assets/images/windows/how-it-works/double-hung.png";
// import pictureWindow from "../../Assets/images/windows/how-it-works/picture-window.png";
// import slidingWindow from "../../Assets/images/windows/how-it-works/sliding-window.png";
// import specialtyWindow from "../../Assets/images/windows/how-it-works/specialty-window.png";
// import awningWindow from "../../Assets/images/windows/how-it-works/awning-window.png";

import Flat from "../../Assets/images/roof/howItWorks/flat.png";
import Clay from "../../Assets/images/roof/howItWorks/Clay.png";
import Metal from "../../Assets/images/roof/howItWorks/Metal.png";
import Slate from "../../Assets/images/roof/howItWorks/slate.png";
import Cedar from "../../Assets/images/roof/howItWorks/Cedar.png";
import Shingle from "../../Assets/images/roof/howItWorks/shingle.png";

import { TypesOfWindows } from "../../Containers/HowItWorks/windows/TypesOfWindows";
import { WindowsEnjoy } from "../../Containers/HowItWorks/windows/WindowsEnjoy";

import Insulatiom from "../../Assets/images/roof/home/insulation.png";
// import Siding from "../../Assets/images/roof/home/Siding.png";
import Skylights from "../../Assets/images/roof/home/skylights.png";
import SoffitFascia from "../../Assets/images/roof/home/soffit-fascia.png";
import RoofLeakRepairs from "../../Assets/images/roof/home/roof-leak-repairs.png";
import NewRoofing from "../../Assets/images/roof/home/new-roofing.png";

import prjvprv7 from "../../Assets/images/roofs1.jpg";
import prjvprv8 from "../../Assets/images/roofs2.jpg";
import prjvprv9 from "../../Assets/images/roofs3.jpg";

const roofMenu = [
  {
    id: 0,
    img: NewRoofing,
    alt: "NewRoofing",
    path: "new-roofing",
    title: "New Roofing",
  },
  {
    id: 1,
    img: RoofLeakRepairs,
    alt: "RoofLeakRepairs",
    path: "/roof-leak-repairs",
    title: "Roof Leak Repairs",
  },
  {
    id: 2,
    img: Insulatiom,
    alt: "Insulatiom",
    path: "insulation",
    title: "Insulation",
  },
  {
    id: 3,
    img: SoffitFascia,
    alt: "SoffitFascia",
    path: "soffit-fascia-eavestrought",
    title: "Soffit, Fascia & Eavestrough",
  },
  {
    id: 4,
    img: Skylights,
    alt: "Skylights",
    path: "skylights",
    title: "Skylights",
  },
  // {
  //   id: 5,
  //   img: Siding,
  //   alt: "Siding",
  //   path: "siding",
  //   title: "Siding",
  // },
];

export const HowItWorksPage = () => {
  const typesWindowsData = [
    {
      id: 0,
      img: Shingle,
      title: "Shingle Roofs",
      paragraths: [
        `Shingle roofs are long-lasting and durable, when using the right
      products and approach. Price points for shingle roofs can vary
      depending on several factors, including whether or not you would
      like architectural, builder-grade or premium shingles. There is also
      a wide variety of styles and colours to complement your home and
      taste.`,
        `The most common materials used for sloped roof shingle applications
      include:`,
      ],
      listItems: ["Asphalt", "Cedar", "Slate", "Ceramic", "Concrete"],
    },
    {
      id: 1,
      img: Cedar,
      title: "Cedar Roofs",
      paragraths: [
        ` The greatest advantages of a cedar roof is the natural and timeless
      beauty. Cedar roofs are also regarded for their durability, energy
      efficiency and resistance to extreme weather. In terms of cost
      savings, cedar wood is long-lasting, which makes an ideal roofing
      material that more than pays for itself. In fact, they are noted to
      last at least 10 years longer than most alternatives, when installed
      correctly`,
        `While they do come at a higher price point, the longevity provides a
      much higher return on investment.`,
      ],

      revert: true,
    },
    {
      id: 2,
      img: Slate,
      title: "Slate Roofs",
      description:
        "A truly elegant option, slate roofs are well known for their beauty, durability and longevity. The largest advantage to installing a slate roof is the appearance. Slate roof tiles are made from 100% natural stone and have a naturally cleft surface, with beautiful colour variation ranging over the entire roof. In addition to being both beautiful and durable, slate roof tiles are also fireproof and environmentally friendly. Although it may seem like an expensive choice, slate roofs pay off in terms of appearance and lifespan. Slate is available in a variety of sizes, colours and thickness to help meet design requirements. Slate is much heavier than standard shingles, so roof reinforcement may be required to ensure a safe and lasting installation.",
    },
    {
      id: 3,
      img: Metal,
      title: "Metal Roofs",
      description: `When properly installed, a metal roof should last as long as the house. They are regarded for sealing out water, surviving high winds and easily shedding snow. Metal is resistant to fire, mildew and rot, while also providing a lightweight and quick installation. `,
      revert: true,
    },
    {
      id: 4,
      img: Clay,
      title: "Clay Roofs",
      paragraths: [
        `   Clay roof tile is made from clay and can be formed into a range of
        shapes. Based on the grade, they can provide your roof and home with
        a range of levels of protection, from severe frost to extreme
        weather and everything in between.`,
        `The most common materials used for sloped roof shingle applications
      include:`,
        `Advantages of clay roofs include:`,
      ],
      listItems: [
        "Designed for a long-lasting installation",
        "Weather resistant, from rain to snow",
        "Easy and convenient maintenance",
        "Suitable for sloped and curved roofs",
      ],
    },
    {
      id: 5,
      img: Flat,
      title: "Flat Roofs",
      description: `In recent years, flat roofs have become a popular choice for many homeowners throughout North America. Both economic and efficient, flat roofs are a modern option that can help to save homeowners both energy and money. The space-saving and compact design is ideal for small structures, extensions, porches, and garages. They are perfect choice for homes that are often exposed to strong winds, as they don’t come into contact with direct impact due to their design, only adding to their longevity. We do suggest that regular inspections are performed to help reduce the risk of issues due to pooling of water or snow.`,
      revert: true,
    },
  ];
  const doorMenu = [
    {
      id: 0,
      img: VinylWindows,
      alt: "vinyl-windows",
      title: "Vinyl Windows",
      path: "vinyl-windows",
    },
    {
      id: 1,
      img: EntryDoors,
      alt: "entry-doors",
      title: "Entry Doors",
      path: "patio-doors",
    },
    {
      id: 2,
      img: PatioDoors,
      alt: "",
      title: "Patio Doors",
      path: "patio-doors",
    },
    {
      id: 3,
      img: GarageDoors,
      alt: "",
      title: "Garage Doors",
      path: "garage-doors",
      left: "-48px",
      top: "-8px",
    },
  ];
  const className = "roof";
  const workStepsItems = [
    {
      id: 0,
      title: "Receive Your Free Quote",
      description: `Schedule your free consultation with our team of roofing experts. We’ll gain a better understanding of your exact needs and quote your project accordingly.`,
      imgProps: {
        src: stepTaplet,
      },
    },
    {
      id: 1,
      title: "Select Your Project Start Date",
      description:
        "We will work with your schedule to plan the project. Based on what works best for you, we will select a project start and estimated end date!",
      imgProps: {
        src: stepPeopleWithTree,
      },
    },
    {
      id: 2,
      title: "Plan Your Roof",
      description: `We take pride in roofing projects that offer the perfect combination of function, quality and aesthetic. Plan your roof, down to the last detail.`,
      imgProps: {
        src: stepPeopleWithLeptop,
      },
    },
    {
      id: 3,
      title: "The Work Begins",
      description: `Our team will work closely with you to ensure a seamless start to your renovation. We will keep you updated as we progress and finish.`,
      imgProps: {
        src: stepAssemblingRoof,
      },
    },
  ];

  return (
    <>
        <Helmet>
          <title>{"AGM Roofing Renovations - How It Works"}</title>
          <meta name="description" content={process.env.REACT_APP_META_DESCRIPTION} />
          <meta name="keywords" content={process.env.REACT_APP_META_KEYWORDS} />
          <meta name="robots" content="index"/>
        </Helmet>

      <DoorsNav className={className} doorMenu={roofMenu} />
      <WindowsTopSection />

      <VideoTestimonials 
        title={'Our Renovation Process'}
        style={{ paddingBottom: '40px', paddingTop: '80px' }}
        items={[
              { videoId: 'D73N0ny-PKs', previewImage: prjvprv8 },
              { videoId: 'BF7jxIU5hBI', previewImage: prjvprv7 },
              { videoId: 'GI9dZ_qx-qs', previewImage: prjvprv9 },
              { videoId: 'GI9dZ_qx-qs', previewImage: prjvprv9 },
        ]} 
      />

      <WorkSteps items={workStepsItems} isWindows={true} />

      <TypesOfWindows
        title="Your Roofing Project"
        typesWindowsData={typesWindowsData}
      />
      <WindowsEnjoy text="Enjoy the Fresh New Look of Your Home!" />
    </>
  );
};
