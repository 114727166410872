import React, { useState, useEffect } from 'react'

function PrivacyPolicy() {
  return (
    <div className="container">
      <h1>Privacy Policy</h1>
      <p><span><br /></span><span><br /></span><strong>Introduction</strong></p>
      <p><span>Welmond Corporation operating as AGM Basements (&ldquo;Welmond&rdquo;) has a practice of protecting the privacy and security of customer, supplier and employee records. We are committed to meeting our obligations under Canadian data privacy laws, including the </span><em><span>Personal Information Protection and Electronic Documents Act</span></em><span>, S.C. 2000, c. 5, and applicable provincial laws. We adhere to the privacy principles set out below, which govern the way we collect, use, store and disclose personal information that is obtained in the course of development, sales, promotion and distribution of our products and services or in the course of employment.</span></p>
      <p><span>&ldquo;Personal information&rdquo;, as used in this policy, means any information about an identifiable person, including employee records, and customer and supplier information, but does not include the name, title, business address, business telephone number, business fax number or business e-mail address of an employee of an organization.</span></p>
      <p><strong>Policy for the Protection of Personal Information:</strong></p>
      <p><span>We will collect, use, store and disclose personal information in accordance with the following privacy principles:</span></p>
      <p><strong>1 Accountability</strong><span>&nbsp;</span></p>
      <p><span>The overall responsibility for ensuring our compliance with data privacy laws and this privacy policy rests with </span><strong>Natalia Borodulya</strong><span>, who is our Privacy Officer, although other individuals within Welmond have responsibility for the day-to-day collection and processing of personal information and may be delegated to act on behalf of the Privacy Officer.&nbsp;</span></p>
      <p><span>We are responsible for personal information in our possession or custody, including personal information that we may transfer to third parties for processing. We will require our service providers to agree to contractual requirements that are consistent with our privacy and security policies. We will require that our service providers are prohibited from using personal information, except for the specific purpose for which we supply it to them.</span></p>
      <p><strong>2 Identifying Purposes&nbsp;&nbsp;</strong></p>
      <p><span>Either before or at the time of collection, we will identify the purposes for which we plan to use the personal information. Depending upon the way in which the personal information is collected, this can be done orally or in writing. Welmond may use the information we collect for the following purposes:</span></p>
      <p><span>(a) to ship products or provide services to our customers;</span></p>
      <p><span>(b) to provide customers access to Welmond products or services;</span></p>
      <p><span>(c) to respond to customer inquiries about accounts and other services;&nbsp;</span></p>
      <p><span>(d) to understand our customers and prospective customers&rsquo; needs and to offer products and services to meet those needs;</span></p>
      <p><span>(e) to conduct credit checks on customers or prospective customers; and&nbsp;</span></p>
      <p><span>(f) to meet legal requirements.</span></p>
      <p><span>(g) We collect information about your property for the purposes of preparing a quote.</span></p>
      <p><span>Unless required by law, we will not use personal information for a new purpose without the knowledge and consent of the individual to whom the information relates.</span></p>
      <p><strong>3 Consent&nbsp;</strong></p>
      <p><span>Personal information will only be collected, used or disclosed with the consent of the individual, except in certain circumstances permitted or required by law. The way in which we seek consent may vary depending upon the sensitivity of the information. We will obtain consent in all cases where the personal information involved is considered sensitive, such as income or health information.&nbsp;</span></p>
      <p><span>Typically, we will seek consent for the use or disclosure of personal information at the time of collection. However, additional consent will be sought after the personal information has been collected if it is required for a new purpose.&nbsp;</span></p>
      <p><span>In certain circumstances, obtaining consent would be inappropriate. The federal </span><em><span>Personal Information Protection and Electronic Documents Act</span></em><span> and provincial privacy laws provide for exceptions where it is impossible or impractical to obtain consent.</span></p>
      <p><strong>4 Limiting Collection&nbsp;</strong></p>
      <p><span>We will collect personal information by fair and lawful means and will limit the amount and type of personal information we collect to that which is necessary for our identified purposes.</span></p>
      <p><span>Personal information will be retained for up to 3 years and will be stored in a locked storage facility on Welmond company premises. Only the Human Resources department and Privacy Officer have access to the locked storage facility in which the personal information records are being retained. After up to 3 years, the personal information will be destroyed in a secure manner and in accordance with applicable privacy legislation.</span></p>
      <p><span>Upon an employee&rsquo;s resignation, retirement or termination of employment, the employee&rsquo;s personal information will be destroyed in a secure manner and in accordance with applicable privacy legislation.</span></p>
      <p><strong>5 Limiting Use, Disclosure and Retention</strong><span>&nbsp;</span></p>
      <p><span>We will not use or disclose personal information for purposes other than those for which it was collected, except with the consent of the individual or as required by law.&nbsp;</span></p>
      <p><strong>6 Affiliates With Whom We Share Information</strong><span>&nbsp;</span></p>
      <p><span>Welmond may provide personal information to Welmond Inc which is bound by this same policy.</span></p>
      <p><strong>7 Accuracy</strong><span>&nbsp;</span></p>
      <p><span>We will use our best efforts to ensure that personal information that is used on an ongoing basis and information that is used to make a decision about an individual is as accurate, complete and up-to-date as necessary for the purpose for which it is to be used.</span></p>
      <p><strong>8 Safeguards</strong><span>&nbsp;</span></p>
      <p><span>We will protect personal information with safeguards appropriate to the level of sensitivity of the information. Our safeguards protect personal information against loss or theft, as well as unauthorized access, disclosure, copying, use or modification, regardless of the format in which the information is held. We will exercise care in the disposal or destruction of personal information to prevent unauthorized parties from gaining access to the information. Our methods of protection include physical measures (</span><em><span>e.g.</span></em><span>, locked file storage and restricted access to offices), organizational measures (</span><em><span>e.g.</span></em><span>, security clearances and limiting access on a need-to-know basis) and technological measures (</span><em><span>e.g.</span></em><span>, the use of passwords and encryption). We also require our outside service providers to provide a comparable level of protection to personal information that we may supply to them.</span></p>
      <p><strong>9 Individual Access</strong><span>&nbsp;</span></p>
      <p><span>Upon written request, we will inform an individual of the existence, use and disclose of his/her personal information and give him/her reasonable access to that information. We may deny access for legally permissible reasons, such as situations where the information is prohibitively costly to provide, if it contains references to other individuals, or where it cannot be disclosed for legal, security or commercial proprietary reasons. We will advise the individual of any reason for denying an access request.</span></p>
      <p><span>When an individual successfully demonstrates the inaccuracy or incompleteness of personal information held by us, we will correct or update the information as required.</span></p>
      <p><strong>10 Filing Inquiries and Complaints</strong><span>&nbsp;&nbsp;</span></p>
      <p><span>We will investigate all written complaints and respond to all written inquiries. If we find a complaint to be justified, we will take appropriate measures to resolve it.</span></p>
      <p><span>To file an opt-out request, request access to your information, report incorrect information or file a complaint, please write to</span></p>
      <p><span>Natalia Borodulya</span></p>
      <p><span>natalia@agmrenovations.com</span></p>      
    </div>
  )
}

export default PrivacyPolicy
