import React from 'react';
import { Link } from "react-router-dom";

import bath_1 from "../../Assets/images/bath_1.png";

export const Intro = (props) => {
  
  const { 
    desc,
    className
  } = props;

  const renderButton = () =>{    
    const isKitchenApp = process.env.REACT_APP_NAME === 'kitchens';

    if(isKitchenApp || true) {
      return <Link to="/get-started" className="blue-btn margin-auto">Get Started</Link>
    } else {
      return <a href="/design" className="blue-btn margin-auto-mobile">Get Started</a>
    }
  }

  return (
    <section className={`works-intro ${className || ''}`}>
      <div className="container">
        <div className="cnt">
          <div className="text">
            <div className="text-cnt text-lft">
              <h3>Feeling inspired?</h3>
              <p>
                {desc}
              </p>
              { renderButton() }
            </div>
          </div>
          <div className="image">
            <div className="image-cnt">
              <img src={bath_1} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}