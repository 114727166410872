import React, { useState } from 'react';
import { Box, TextField, Button } from '@mui/material';
import PropTypes from 'prop-types';
import xicon from '../../Assets/Icons/x-symbol-svgrepo-com.svg';


const styles = {
  color: '#19ace4',
  background: '#eee',
  padding: '6px',
  borderRadius: '50%',
  cursor: 'pointer',
  position: 'absolute',
  width: '30px',
  height: '30px',
  margin: '-15px -15px 0 0',
  border: '1px solid #bbb',
  top: '0',
  right: '0',
};

const modalStyle = {
  backgroundColor: '#fff',
  borderRadius: 5,
  maxWidth: 500,
  minHeight: 180,
  margin: '0 auto',
  padding: 25,
  position: 'relative',
};

const imgStyle = {
  float: 'left',
  marginRight: 10,
  maxWidth: '32px',
  opacity: '0.6'
};


export const Modal = ({ onClose, onClick, show, image, header, country }) => {
  const [code, setCode] = useState('');

  const handleInputChange = (e) => {
    setCode(e.target.value);
  }

  if (!show) return null;

  return (
    <div className="backdrop">
      <div className="modal" style={modalStyle}>
        <div className="modal-header">
          <img
            className="model-img"
            style={imgStyle}
            alt="geolocation"
            src={image}
          />
          <h2 style={{ color: '#686868' }}>{header}</h2>
        </div>

        <div className="footer"/>

        <form onSubmit={onClick}>
          <Box display="flex" alignItems="center">
            <Box pr={2} style={country === 'United States' ? {width: '245px'} : {width: '295px'}} flexGrow={1}>
              <TextField
                fullWidth
                size="small"
                type="text"
                name="postal_code"
                label={
                  country === 'United States'
                    ? 'ZIP Code'
                    : 'Postal Code'
                }
                variant="outlined"
                placeholder={
                  country === 'United States'
                    ? 'Enter ZIP Code'
                    : 'Enter Postal Code'
                }

                InputProps={{
                  inputProps: {
                    type: 'string',
                    minLength: 5,
                    maxLength: 7
                  }
                }}

                value={code}
                onChange={handleInputChange}
              />
            </Box>

            <Button type="submit" style={{backgroundColor: '#46bfee'}} variant="contained">
              SUBMIT
            </Button>
          </Box>
        </form>

        <img src={xicon} alt="close" style={styles} onClick={onClose}/>
      </div>
    </div>
  )
}

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  show: PropTypes.bool.isRequired,
  header: PropTypes.string,
  country: PropTypes.string,
};

export default Modal;
