import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../Assets/images/bath_logo.svg";

export const Content = (props) => {
  const {
    title = "Who we are What we stand for", //"The #1 Bathroom Design & Renovation Experts",
    text = "We provide turnkey design/built bathroom renovation packages. Since 1998, AGM Renovations has created tens of thousands of unique bathrooms. As highest rated renovators, we’ve long been recognized for our quality craftsmanship, customer service, and our team of experts who can turn your old bathroom into a spa oasis.",
    paragrapht,
  } = props;

  let region = "";
  if (sessionStorage.getItem("region")) {
    region = sessionStorage.getItem("region");
  } else {
    region = "";
  }

  const renderButton = () => {
    const isKitchenApp = process.env.REACT_APP_NAME === "kitchens";
    // if(region==='vancouver' || region ==='calgary'){
    if (isKitchenApp || true) {
      return (
        <Link to="/get-started" className="blue-btn margin-auto">
          GET STARTED
        </Link>
      );
    } else {
      return (
        <a href="/design" className="blue-btn margin-auto-mobile">
          GET STARTED
        </a>
      );
    }
  };

  return (
    <section className="home-intro about-us">
      <div className="container">
        <div className="top-cnt">
          {/* <span className="logo">
          <img src={Logo} alt="AGM Bathrooms" />
        </span> */}
          <h2>{title}</h2>
        </div>

        {paragrapht ? (
          paragrapht.map((paragraph, index) => <p key={index}>{paragraph}</p>)
        ) : (
          <p>{text}</p>
        )}

        {/* {renderButton()} */}
        <a href="/blog" style={{ padding: '10px 40px', background: 'linear-gradient( 90deg, rgb(29 60 90) 0%, rgb(6 127 161) 100% )' }} className="blue-btn margin-auto">AGM Blog</a>
      </div>
    </section>
  );
};
