import React from "react";
import "../Home/windows/WindowsHomeTop.scss";

import { WindowsIntro as CallToAction } from "../OurWork/windows/WindowsIntro";
import { DoorsNav } from "../DoorsNav/DoorsNav";

import { TopIntro } from "../../Common/ServicesPages/TopIntro";
import { Features } from "../../Common/ServicesPages/Features";
import { useBreakPoint } from "../../hooks/useResponsive";
import OurColection from "../OurColection/OurColection";

import wnds_top from "../../Assets/images/windows/vinil/wnds_top.png";
import bcknd_top from "../../Assets/images/windows/vinil/top_background.png";
import calltoaction_bcknd from "../../Assets/images/windows/vinil/vinyl_calltoaction.jpg";

import VinylWindowsImg from "../../Assets/images/windows/how-it-works/vinyl-windows.png";
import EntryDoorsImg from "../../Assets/images/windows/how-it-works/entry-doors.png";
import PatioDoorsImg from "../../Assets/images/windows/how-it-works/patio-doors.png";
import GarageDoorsImg from "../../Assets/images/windows/how-it-works/garage.png";

import Casement from "../../Assets/images/windows/vinil/casement.png";
import Hung from "../../Assets/images/windows/vinil/hung.png";
import Hopper from "../../Assets/images/windows/vinil/hopper.png";
import Awning from "../../Assets/images/windows/vinil/awning.png";
import SingleSlider from "../../Assets/images/windows/vinil/single-slider.png";
import DoubleSlider from "../../Assets/images/windows/vinil/double-slider.png";
import Bay from "../../Assets/images/windows/vinil/bay.png";
import Bow from "../../Assets/images/windows/vinil/bow.png";
import ProductSpecification from "../ProductSpecification/ProductSpecification";

import BackgroundTopSm from "../../Assets/images/windows/vinil/vinyl-backgrount-sm.png";
import backgroundMobile from "../../Assets/images/windows/vinil/background-mobile-top.png";
import FeelingBackgroundMobile from "../../Assets/images/windows/vinil/feeling-background-mobile.png";

const checkList = [
  "Completing our projects on time ",
  "Completing our projects within budget",
  "No added or hidden costs",
  "Decades of customer satisfaction",
];
const doorMenu = [
  {
    id: 0,
    img: VinylWindowsImg,
    alt: "vinyl-windows",
    path: "vinyl-windows",
    title: "Vinyl Windows",
  },
  {
    id: 1,
    img: EntryDoorsImg,
    alt: "entry-doors",
    path: "entry-doors",
    title: "Entry Doors",
  },
  {
    id: 2,
    img: PatioDoorsImg,
    alt: "",
    path: "patio-doors",
    title: "Patio Doors",
  },
  {
    id: 3,
    img: GarageDoorsImg,
    alt: "",
    path: "garage-doors",
    title: "Garage Doors",
    left: "-48px",
    top: "-8px",
  },
];
const TopText = {
  title: "Vinyl Windows",
  subtitle: `Updating your home with vibrant, fresh vinyl windows can boost the curb appeal of your home, all while keeping your bills low 
  with energy-efficient designs and technology. With their expansive 
  range of styles, you can easily discover breathtaking views and brighten up each room with a unique window that's perfect for your home.`,
  subtitle2: `Find the vinyl window that meets your style, and our team will ensure your 
  vision comes to life as your new windows are installed on time, on budget, and by an unmatched professional team.`,
};
const FeaturesText = {
  title: "Our Vinyl Windows",
  subtitle: "AGM Renovation Guarantees",
  imageProps: {
    position: "10%",
  },
  backgroundSize: "40%",
  containerClass: "doors",
  checkList: [
    "Completing projects on time",
    "Keeping projects within budget",
    "No hidden or surprise costs",
    "Decades of customer satisfaction",
  ],
};

const OurWorks = [
  { id: 0, img: Casement, alt: "Casement", title: "Casement" },
  { id: 1, img: Hung, alt: "Hung", title: "Hung" },
  { id: 2, img: Hopper, alt: "Hopper", title: "Hopper" },
  { id: 3, img: Awning, alt: "Awning", title: "Awning" },
  { id: 4, img: SingleSlider, alt: "Single Slider", title: "Single Slider" },
  { id: 5, img: DoubleSlider, alt: "Double Slider", title: "Double Slider" },
  { id: 6, img: Bay, alt: "Bay", title: "Bay" },
  { id: 7, img: Bow, alt: "Bow", title: "Bow" },
];

const VinylWindows = (props) => {
  const breakPoint = useBreakPoint();
  return (
    <>
      <section className="windowsHomeTop">
        <DoorsNav doorMenu={doorMenu} active={"vinyl-windows"} />
      </section>
      <TopIntro
        backgroundImage={bcknd_top}
        backgroundSm={BackgroundTopSm}
        backgroundMobile={backgroundMobile}
        {...TopText}
      />
      <Features
        backgroundImage={wnds_top}
        checkList={checkList}
        imgMobile={wnds_top}
        {...FeaturesText}
      />
      <OurColection
        items={OurWorks}
        title={"Our Window Collection"}
        subtitle={"Explore our vinyl windows and discover your perfect fit!"}
      />
      {breakPoint !== 980 ? <ProductSpecification /> : <></>}

      <CallToAction backgrounMobileIntro={FeelingBackgroundMobile} />
    </>
  );
};

export default VinylWindows;
