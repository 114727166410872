import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./font-awesome.min.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

//import Image from "./Assets/images/example.jpeg";

ReactDOM.render(<App />, document.getElementById("root"));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// import { PDFDownloadLink } from '@react-pdf/renderer';

// import pdfDocument from "./Views/bathroomForm";

// const form = {
//     address: "90283 Address Brampton On",
//     ageHome: "26 years",
//     sizeOfHome: "9834 Sq Feet",
//     powderRoom: "No",
//     bathroom: "No",
//     masterBathroom: "Yes",
//     link:"https://bathroomlayoutfiles.s3.amazonaws.com/bathroom_layout-be526ca9-fc71-4594-b9f5-25a8f75bb13b.zip"
// }

// const images = {
//     layout: Image,
//     backwall: Image,
//     frontwall: Image,
//     leftwall: Image,
//     rightwall: Image,
// };

// const pdf = <pdfDocument data={form} images={images} />

// const App = (data) => (
//     <div>
//       <PDFDownloadLink document={pdf} fileName="somename.pdf">
//         {({ blob, url, loading, error }) =>
//           loading ? 'Loading document...' : 'Download now!'
//         }
//       </PDFDownloadLink>
//     </div>
//   );

//   ReactDOM.render(<App />, document.getElementById('root'));
