import React from "react";
import { DoorsNav } from "../../DoorsNav/DoorsNav";
import { TopIntro } from "../../../Common/ServicesPages/TopIntro";
import { Features } from "../../../Common/ServicesPages/Features";
import { TypesOfWindows } from "../../HowItWorks/windows/TypesOfWindows";
import { WindowsIntro as CallToAction } from "../../OurWork/windows/WindowsIntro";

import Insulatiom from "../../../Assets/images/roof/home/insulation.png";
import Siding from "../../../Assets/images/roof/home/Siding.png";
import Skyli from "../../../Assets/images/roof/home/skylights.png";
import SoffitFascia from "../../../Assets/images/roof/home/soffit-fascia.png";
import RoofLeakRepairs from "../../../Assets/images/roof/home/roof-leak-repairs.png";
import NewRoofing from "../../../Assets/images/roof/home/new-roofing.png";

import Solar from "../../../Assets/images/roof/skylight/solar.png";
import Electric from "../../../Assets/images/roof/skylight/electric.png";
import Manual from "../../../Assets/images/roof/skylight/manual.png";
import Fixed from "../../../Assets/images/roof/skylight/fixed.png";

import bcknd_top from "../../../Assets/images/roof/skylight/SkylightsTopBackg.png";
import features_bcknd from "../../../Assets/images/roof/skylight/skylightInstallation.png";
import calltoaction_bcknd from "../../../Assets/images/roof/skylight/skylightBottomBackg.png";

import backgroundMobile from "../../../Assets/images/roof/skylight/topBackgMobile.png";
import ServicesMobile from "../../../Assets/images/roof/skylight/servicesMobile.png";
import FeelingBackgroundMobile from "../../../Assets/images/roof/skylight/bottomBackgMobile.png";
const doorMenu = [
  {
    id: 0,
    img: NewRoofing,
    alt: "NewRoofing",
    path: "new-roofing",
    title: "New Roofing",
  },
  {
    id: 1,
    img: RoofLeakRepairs,
    alt: "RoofLeakRepairs",
    path: "/roof-leak-repairs",
    title: "Roof Leak Repairs",
  },
  {
    id: 2,
    img: Insulatiom,
    alt: "Insulatiom",
    path: "insulation",
    title: "Insulation",
  },
  {
    id: 3,
    img: SoffitFascia,
    alt: "SoffitFascia",
    path: "soffit-fascia-eavestrought",
    title: "Soffit, Fascia & Eavestrough",
    // left: "-48px",
    // top: "-8px",
  },
  {
    id: 4,
    img: Skyli,
    alt: "Skylights",
    path: "skylights",
    title: "Skylights",
    // left: "-48px",
    // top: "-8px",
  },
  // {
  //   id: 5,
  //   img: Siding,
  //   alt: "Siding",
  //   path: "siding",
  //   title: "Siding",
  //   // left: "-48px",
  //   // top: "-8px",
  // },
];
const className = "roof";

const TopText = {
  title: "Skylight Installation & Repair",
  subtitle: `It’s hard to deny, the installation of a skylight can truly transform the appeal of your space. With a skylight, you can allow natural light to enter your home, all while adding the option for ventilation. To help ensure that your skylight serves you for many years to come, professional installation and maintenance is always recommended.`,
  subtitle2: `The perfect combination of functional and appealing, find out how a skylight can boost the value and enjoyment of your home.`,
  btnTxt: "GET FREE QUOTE",
};
const FeaturesText = {
  title: "Skylight Services",
  containerClass: "roof",
  subtitle: "We proudly offer the following:",
  btntxt: "CONTACT US TODAY",
  checkList: [
    "Skylight installation",
    "Skylight replacements",
    "Repairs, leaks and tarping",

    // " Lifetime Warranty & Satisfaction Guaranteed",
    // "Friendly & Understanding Customer Service",
  ],
};

const typesWindowsData = [
  {
    id: 0,
    img: Solar,
    title: "Solar-Powered Venting Skylights",
    paragraths: [
      ` Enjoy a seamless skylight installation and operation, with help from
      solar power technology. This innovative skylight allows natural light
      to flow into your home and opens/closes for added ventilation. All
      without the need for added electrical wiring!`,
    ],
    listItems: [
      "Increased energy-efficiency with solar power technology",
      "User-friendly operator and control system to operate the skylight",
      "Solar powered approach negates the need for electrical wiring",
    ],
  },
  {
    id: 1,
    img: Electric,
    title: "Electric Venting Skylights",
    paragraths: [
      ` The Velux Electric Skylight is arguably one of the best solutions for
      overhead applications. Designed for both deck and curb mount
      installations, this skylight operates with the simple touch of a
      button.`,
    ],
    listItems: [
      "Ideal for overhead, deck and curb mount applications",
      "Operate the skylight with the simple touch of a button",
      "Rain sensor closes the window during inclement weather",
    ],

    revert: true,
  },
  {
    id: 2,

    img: Manual,
    title: "Manual Venting Skylights",
    paragraths: [
      ` Could your home benefit from better moisture balance and air flow? Add
      to the ventilation of your living space with the Manual Venting
      Skylight from Velux.`,
    ],
    listItems: [
      "Enjoy fresh air and natural light",
      "Longevity with quality materials",
      "Designed for a user-friendly experience",
    ],
  },
  {
    id: 3,
    img: Fixed,
    title: "Fixed Skylights",
    paragraths: [
      `Does your home lack natural light? Illuminate the darkest parts of
      your home naturally, with a quality skylight installation. At AGM
      Renovations, we believe that the addition of a skylight can help to
      boost the appeal of your home, not only adding to the value long-term,
      but also your enjoyment.`,
    ],
    listItems: [
      "Designed for deck mounted installations",
      "Visually expanding areas like hallways and stairwells",
      "An economical option to increase natural light",
    ],

    revert: true,
  },
];
const ourWorkTitle = "The VELUX Skylight";

const Skylights = () => {
  return (
    <section>
      <DoorsNav className={className} doorMenu={doorMenu} active="skylights" />
      <TopIntro
        backgroundImage={bcknd_top}
        backgroundSm={bcknd_top}
        backgroundMobile={backgroundMobile}
        {...TopText}
      />
      <Features
        backgroundImage={features_bcknd}
        // checkList={checkList}
        imgMobile={ServicesMobile}
        {...FeaturesText}
      />
      <TypesOfWindows
        typesWindowsData={typesWindowsData}
        title={ourWorkTitle}
        subtitle={"Discover what the Velux Brand has to offer."}
      />

      <CallToAction
        backgroundImageIntro={calltoaction_bcknd}
        backgrounMobileIntro={FeelingBackgroundMobile}
        textHead="Begin Your Next Project"
        textSubHead="Bring natural light, ventilation and appeal with a quality skylight installation!"
        textBtn="BOOK FREE CONSULTATION"
      />
    </section>
  );
};
export default Skylights;
