import React from "react";
import {
  PDFDownloadLink,
  Image,
  Link,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import Logo from "../Assets/images/logo.jpg";
import { PlayCircleFilledWhite } from "@material-ui/icons";

// Create styles
const styles = StyleSheet.create({
  page: {},
  section: {
    margin: 10,
    padding: 10,
  },
  sectionImage: {
    marginTop: 10,
  },
  span: {
    display: "inline-block",
    minWidth: 160,
  },
  header: {
    padding: "5px 10px",
    display: "inline-block",
    marginBottom: 15,
    backgroundColor: "#E4E4E4",
    fontWeight: 600,
    fontSize: "20px",
  },
  logo: {
    margin: 40,
    maxWidth: 220,
    height: "auto",
  },
  image: {},
  link: {
    color: "blue",
    fontSize: 12,
  },
  float: {},
});

// Create Document Component
export default function PdfDocument(props) {
  let bathroomType = "";

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image style={styles.logo} src={Logo}></Image>
        <br />

        <View style={styles.section}>
          <Text style={styles.header}>Bathroom Information</Text>
          <br />
          <Text style={styles.section}>
            <span style={styles.span}>Address:</span>
            <b>{props.data.address}</b>
          </Text>
          <br />
          <Text style={styles.section}>
            <span style={styles.span}>Age of House:</span>
            <b>{props.data.ageHome}</b>
          </Text>
          <br />
          <Text style={styles.section}>
            <span style={styles.span}>Size of House:</span>
            <b>{props.data.sizeOfHome}</b>
          </Text>
          <br />
        </View>
        <br />

        <View style={styles.section}>
          <Text style={styles.header}>Bathroom Selected</Text>
          <br />
          <Text style={styles.section}>
            <span style={styles.span}>Bathroom Master:</span>
            <b>{props.data.masterBathroom}</b>
          </Text>
          <br />
          <Text style={styles.section}>
            <span style={styles.span}>Bathroom Powder:</span>
            <b>{props.data.powderRoom}</b>
          </Text>
          <br />
          <Text style={styles.section}>
            <span style={styles.span}>Bathroom Normal:</span>
            <b>{props.data.bathroom}</b>
          </Text>
          <br />
        </View>

        <View style={styles.section}>
          <Text style={styles.header}>Bathroom Layout Images</Text>
          <Link style={styles.link} src={props.data.link}>
            {props.data.link}
          </Link>
        </View>
      </Page>

      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.float}>
            <span>Layout</span>
          </Text>
          <Image style={styles.image} src={props.images.layout}></Image>
        </View>
      </Page>

      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.float}>
            <span>backwall</span>
          </Text>
          <Image style={styles.image} src={props.images.backwall}></Image>
        </View>
      </Page>

      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.float}>
            <span>frontwall</span>
          </Text>
          <Image style={styles.image} src={props.images.frontwall}></Image>
        </View>
      </Page>

      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.float}>
            <span>leftwall</span>
          </Text>
          <Image style={styles.image} src={props.images.leftwall}></Image>
        </View>
      </Page>

      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.float}>
            <span>rightwall</span>
          </Text>
          <Image style={styles.image} src={props.images.rightwall}></Image>
        </View>
      </Page>
    </Document>
  );
}
