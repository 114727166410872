import React from "react";

import "./WindowsEnjoy.scss";
export const WindowsEnjoy = ({ text }) => {
  return (
    <section id="windowsEnjoy">
      {text ? (
        <h2>{text}</h2>
      ) : (
        <h2>
          Enjoy The Fresh New Look
          <br /> of Your Home !
        </h2>
      )}
    </section>
  );
};
